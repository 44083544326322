import {
  differenceInDays,
  format,
  formatDistance,
  isToday,
  isYesterday,
  subDays,
} from 'date-fns';
import { EXCEPTIONS_TO_MATCH } from '../constants';

export const determineChipColorAndLabel = (exception) => {
  const label = EXCEPTIONS_TO_MATCH[exception]
    ? EXCEPTIONS_TO_MATCH[exception].exception_text
    : 'Unknown Exception';
  const previouslyDeclinedExceptionText =
    EXCEPTIONS_TO_MATCH.previously_declined.exception_text;
  const color = label === previouslyDeclinedExceptionText ? 'error' : 'warning';

  return { color, label };
};

export const determineDateDistance = (date) => {
  if (!date) {
    return 'N/A';
  }

  const parsedDate = new Date(date);
  const today = new Date();

  if (isToday(parsedDate)) {
    return 'Today';
  }
  if (isYesterday(parsedDate)) {
    return 'Yesterday';
  }

  const difference = differenceInDays(today, parsedDate);

  return formatDistance(subDays(today, difference), today, {
    addSuffix: true,
  });
};

export const formatRecentActivityDate = (date) => {
  if (!date) {
    return null;
  }

  const formattedDate = format(new Date(date), 'M/d');

  return `(${formattedDate})`;
};
