import * as React from 'react';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

function MenuLink(props) {
  const { href, id, children, isActive } = props;
  const {
    palette: {
      tertiary: { main },
    },
  } = useTheme();

  return (
    <Link
      id={id}
      as={NavLink}
      to={href}
      key={id}
      underline="none"
      css={{
        color: 'white',
        '&.active, &:hover': {
          color: main,
        },
        '&.active .MuiChip-filled': {
          backgroundColor: main,
        },
        '&:hover .MuiChip-filled': {
          backgroundColor: main,
        },
        marginLeft: '30px',
        display: 'flex',
        alignItems: 'center',
      }}
      className={isActive ? 'active' : ''}
    >
      {children}
    </Link>
  );
}

export default MenuLink;
