import store from '../../../state/store';
import { setIsAuthorized } from '../../../state/ducks/access/actions';
import { VIEWS } from '../../config';
import * as index from '../../../index';

export default function authorizationInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    (response) => {
      store.dispatch(setIsAuthorized(true));
      return Promise.resolve(response);
    },
    async (error) => {
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 403) {
          store.dispatch(setIsAuthorized(false));
          if (index.router.state.location.pathname !== VIEWS.private.home) {
            await index.router.navigate(VIEWS.private.home);
          }
        }
      }
      return Promise.reject(error);
    }
  );
}
