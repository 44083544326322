import React from 'react';
import { Button } from '@mui/material';
import { MapsUgcOutlined } from '@mui/icons-material';

function ActivityEventCommentButton({ setCommentBoxOpen }) {
  return (
    <Button
      size="large"
      className="add-comment-button"
      onClick={() => {
        setCommentBoxOpen(true);
      }}
    >
      Comment
      <MapsUgcOutlined
        css={{
          marginLeft: '8px',
        }}
      />
    </Button>
  );
}

export default ActivityEventCommentButton;
