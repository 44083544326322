import { DEFAULT_VIEW_ASSOCIATE } from './constants';
import * as types from './types';

const viewAssociateReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_VIEW_ASSOCIATE,
  action
) => {
  switch (action.type) {
    case types.SET_VIEW_ASSOCIATE: {
      const associate = action.payload;
      return {
        ...state,
        ...associate,
      };
    }
    default:
      return state;
  }
};

export default viewAssociateReducer;
