import React from 'react';
import PageLayout from 'components/PageLayout';
import Associates from './components/Associates';

function AssociatesContainer() {
  return (
    <PageLayout>
      <Associates />
    </PageLayout>
  );
}

export default AssociatesContainer;
