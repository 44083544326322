import React from 'react';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom/client';
import store, { persistor } from './state/store';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { MUI_PRO_LICENSE_KEY } from './core/config';

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);

export const router = createBrowserRouter([{ path: '*', element: <App /> }]);
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
