import React from 'react';

import { Box, Button } from '@mui/material';
import ErrorPage from '../../components/ErrorPage';
import { removeAllFromCacheThatStartWith } from '../../services/cache';
import { ReactComponent as ErrorIcon } from '../../assets/empty-states/5xx-error.svg';

function FallBack() {
  return (
    <ErrorPage icon={ErrorIcon}>
      <Box>
        Something unexpected has happened and an error has been logged. To
        continue, use the Reset button below to clear the site cache.
      </Box>
      <Button
        id="fallback-reset"
        variant="outlined"
        sx={{
          margin: '6px',
        }}
        onClick={() => {
          removeAllFromCacheThatStartWith(['boostRetail']);
          window.location.reload();
        }}
      >
        Reset
      </Button>
      <Box>
        If the problem continues, please call the Boost Helpdesk at
        1-888-900-4275.
      </Box>
    </ErrorPage>
  );
}

export default FallBack;
