import React from 'react';
import { Typography } from '@mui/material';
import { ErrorWrapper } from 'components/ErrorPage/style';

function DataGridErrorView({ cssOverride }) {
  return (
    <ErrorWrapper cssOverride={cssOverride}>
      <Typography id="error-message" variant="h6">
        Whoopsie Daisy!
      </Typography>
      <Typography id="error-description" variant="body1">
        Something that should never happen seems to have happened and we&apos;re
        terribly sorry.
      </Typography>
    </ErrorWrapper>
  );
}

export default DataGridErrorView;
