import React from 'react';

import {
  AssociateWorkPreferencesRowWrapper,
  AvailabilityTypography,
} from '../style';

function AssociateAvailabilityInfoRow({ label, children }) {
  return (
    <AssociateWorkPreferencesRowWrapper>
      <AvailabilityTypography variant="body1">{label}</AvailabilityTypography>
      {children}
    </AssociateWorkPreferencesRowWrapper>
  );
}

export default AssociateAvailabilityInfoRow;
