import HTTP from '../../../core/http';
import * as actions from './actions';
import { abort } from '../abort/operations';

export const fetchEventLogs =
  (page, rowsPerPage, id, isRead) => async (dispatch, getState) => {
    const sectionKey = 'fetchEventLogs';

    await dispatch(abort(sectionKey));
    const {
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const initialParams = {
      page_size: rowsPerPage,
      page: page + 1,
    };
    const params = !isRead
      ? {
          ...initialParams,
          is_read: isRead,
        }
      : initialParams;
    return HTTP.get(`/availability/user/${id}/event-log/`, {
      params,
      signal,
    })
      .then((response) => {
        dispatch(actions.setEventLogs(response.data.results));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching event logs');
        }
      });
  };
