import HTTP from '../../core/http';

export const getVersion = () =>
  new Promise((resolve, reject) => {
    HTTP.get('health/version-check')
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
