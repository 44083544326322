import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import { ExpandMore } from '@mui/icons-material';
import { format, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { displayMaxDailyHours } from 'containers/Associate/utils';
import { getAssociatePreferences } from '../../../services/user';
import Error from './Error';
import { scrollIntoView } from '../../../lib/utils';
import { defaultColor } from '../../../styles/theme';
import HomeStoreLocation from './HomeStoreLocation';
import LoadingSpinner from './LoadingSpinner';

function WorkPreferencesCollapsableHeader({ associate, job, selectedDay }) {
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [workPreferences, setWorkPreferences] = useState(null);
  const [workPreferencesError, setWorkPreferencesError] = useState(null);

  const handleDayChange = (date) => {
    setIsLoading(true);
    if (date) {
      const effectiveDate = zonedTimeToUtc(
        startOfDay(date),
        job.store_location_timezone
      );
      getAssociatePreferences({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          setIsLoading(false);
          setWorkPreferences(results);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!axios.isCancel(error)) {
            setWorkPreferencesError(
              error.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setWorkPreferencesError(error);
        });
    }
  };

  useEffect(() => {
    handleDayChange(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    scrollIntoView(expanded, 'work-preferences-accordion-details', 400);
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded}
      square
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        aria-controls="panel1b-content"
        id="panel1b-header"
      >
        <Box id="work-preferences">
          <Typography variant="subtitle1">Work Preferences</Typography>
          <Typography variant="body2" id="selected-day-work-preferences">
            {format(selectedDay, 'MMMM do, yyyy, EEEE', {
              timeZone: job.store_location_timezone,
            })}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails id="work-preferences-accordion-details">
        {isLoading && <LoadingSpinner id="associate-preferences-loading" />}

        {workPreferencesError && !isLoading ? (
          <Error
            id="associate-preferences-error"
            doRetry={() => {
              setWorkPreferencesError(null);
              handleDayChange(selectedDay);
            }}
          />
        ) : (
          <>
            {workPreferences && !isLoading ? (
              <>
                {associate.home_store_location && (
                  <Box
                    css={{
                      borderRadius: '10px',
                      border: '1px solid #c6cace',
                      margin: '8px 16px 16px 16px',
                      backgroundColor: defaultColor,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      id="home-store"
                      css={{ display: 'flex', padding: '10px' }}
                    >
                      Home Store
                    </Typography>
                    <Divider />
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '12px',
                      }}
                    >
                      <HomeStoreLocation
                        homeStoreLocation={associate.home_store_location}
                      />
                      {workPreferences.my_home_store_only && (
                        <Chip
                          color="warning"
                          label="Only"
                          id="home-store-only-chip"
                        />
                      )}
                    </Box>
                  </Box>
                )}
                <Box
                  css={{
                    borderRadius: '10px',
                    border: '1px solid #c6cace',
                    margin: '8px 16px 16px 16px',
                    backgroundColor: defaultColor,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    css={{ display: 'flex', padding: '10px' }}
                  >
                    Team/Retailer
                  </Typography>
                  <Box css={{ borderBottom: '1px solid #c6cace' }} />
                  <Box
                    css={{ display: 'flex', padding: '10px' }}
                    id="team-retailer"
                  >
                    <Typography variant="body2" css={{ width: '50%' }}>
                      {associate.division_name}
                    </Typography>
                  </Box>
                </Box>
                {!workPreferences.my_home_store_only && (
                  <Box
                    css={{
                      borderRadius: '10px',
                      border: '1px solid #c6cace',
                      margin: '8px 16px 16px 16px',
                      backgroundColor: defaultColor,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      css={{ display: 'flex', padding: '10px' }}
                    >
                      Travel
                    </Typography>
                    <Box css={{ borderBottom: '1px solid #c6cace' }} />
                    <Box
                      css={{ display: 'flex', padding: '10px' }}
                      id="max-commute-miles"
                    >
                      <Typography variant="body2" css={{ width: '50%' }}>
                        Willing to drive
                      </Typography>
                      <Typography
                        variant="body2"
                        css={{
                          width: '50%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {workPreferences.maximum_commute_miles !== 0
                          ? `${workPreferences.maximum_commute_miles} miles`
                          : 'Unlimited'}
                      </Typography>
                    </Box>
                    <Box
                      css={{ display: 'flex', padding: '10px' }}
                      id="overnight-stay"
                    >
                      <Typography variant="body2" css={{ width: '50%' }}>
                        Travel and stay overnight
                      </Typography>
                      <Typography
                        variant="body2"
                        css={{
                          width: '50%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {workPreferences.allow_overnight_stay ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  css={{
                    borderRadius: '10px',
                    border: '1px solid #c6cace',
                    margin: '8px 16px 16px 16px',
                    backgroundColor: defaultColor,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    css={{ display: 'flex', padding: '10px' }}
                  >
                    Availability
                  </Typography>
                  <Box css={{ borderBottom: '1px solid #c6cace' }} />
                  <Box
                    css={{ display: 'flex', padding: '10px' }}
                    id="preferred-weekly-hours"
                  >
                    <Typography variant="body2" css={{ width: '50%' }}>
                      Preferred hours a week
                    </Typography>
                    <Typography
                      variant="body2"
                      css={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {workPreferences.preferred_weekly_hours} hours
                    </Typography>
                  </Box>
                  <Box
                    css={{ display: 'flex', padding: '10px' }}
                    id="max-daily-hours"
                  >
                    <Typography variant="body2" css={{ width: '50%' }}>
                      Maximum hours per day
                    </Typography>
                    <Typography
                      variant="body2"
                      css={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {displayMaxDailyHours(
                        workPreferences.preferred_daily_hours
                      )}
                    </Typography>
                  </Box>
                  {workPreferences.day_availability.length === 0 ? (
                    <Typography
                      variant="subtitle2"
                      id="no-work-preferences-div"
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      No availability for this associate
                    </Typography>
                  ) : (
                    workPreferences.day_availability.map(
                      (availability, index) => {
                        const startWithTz = utcToZonedTime(
                          availability.start,
                          job.store_location_timezone
                        );
                        const endWithTz = utcToZonedTime(
                          availability.end,
                          job.store_location_timezone
                        );
                        const formattedStartTime = format(
                          startWithTz,
                          'h:mm a',
                          {
                            timeZone: job.store_location_timezone,
                          }
                        );
                        const formattedEndTime = format(endWithTz, 'h:mm a', {
                          timeZone: job.store_location_timezone,
                        });
                        const formattedEndDisplayTime =
                          formattedEndTime !== '12:00 AM'
                            ? formattedEndTime
                            : '11:59 PM';
                        return (
                          <Box
                            key={availability.start}
                            id={`availability-${index}`}
                            css={{ display: 'flex', padding: '10px' }}
                          >
                            <Typography variant="body2" css={{ width: '50%' }}>
                              {format(selectedDay, 'EEEE', {
                                timeZone: job.store_location_timezone,
                              })}
                            </Typography>
                            <Typography
                              variant="body2"
                              css={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {`${formattedStartTime} - ${formattedEndDisplayTime}`}
                            </Typography>
                          </Box>
                        );
                      }
                    )
                  )}
                </Box>
              </>
            ) : null}
            {!workPreferences && !isLoading ? (
              <Typography
                variant="subtitle2"
                id="no-work-preferences-div"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                No availability for this associate
              </Typography>
            ) : null}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default WorkPreferencesCollapsableHeader;
