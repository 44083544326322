import React from 'react';

import { Skeleton } from '@mui/material';
import { AssociateDetailsInfo } from 'containers/Associate/style';

export function SkeletonAssociateDetailsInfo() {
  return (
    <AssociateDetailsInfo>
      <Skeleton
        variant="circular"
        css={{
          marginRight: '16px',
          fontSize: '20px',
          width: 40,
          height: 40,
        }}
      />
      <div>
        <Skeleton
          variant="text"
          css={{
            fontSize: '24px',
            width: 200,
          }}
        />
        <Skeleton
          variant="text"
          css={{
            fontSize: '16px',
            width: 200,
          }}
        />
        <Skeleton
          variant="text"
          css={{
            fontSize: '14px',
            width: 100,
          }}
        />
      </div>
    </AssociateDetailsInfo>
  );
}
