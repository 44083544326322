import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { theme } from 'styles/theme';
import styled from '@emotion/styled/macro';

export const BatchActionButton = styled(ToggleButton)`
  && {
    border-color: #c6cace;
    font-size: 14px;
    color: ${theme.palette.text.secondary};
    &:hover {
      background-color: #ebf6fa;
      color: ${theme.palette.primary.main};
    }
    &.Mui-selected {
      background-color: #ebf6fa;
      color: ${theme.palette.primary.main};
    }
  }
`;

export const BatchActionGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (props) => props !== 'showAutoStaff',
})(({ showAutoStaff }) => ({
  backgroundColor: theme.palette.backgroundColor.secondary,
  ...(!showAutoStaff && {
    '& .MuiToggleButton-root:nth-of-type(2)': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      marginLeft: '0px',
    },
  }),
  '& .MuiToggleButtonGroup-middleButton ': {
    marginLeft: '0px',
    borderLeft: 0,
  },
  '& .MuiToggleButtonGroup-lastButton': {
    marginLeft: '0px',
    borderLeft: 0,
  },
}));
