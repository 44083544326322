import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Button, Divider } from '@mui/material';
import React from 'react';

function ActivityEventCommentBox({
  addedComment,
  setAddedComment,
  commentBoxOpen,
  setCommentBoxOpen,
  handleSave,
}) {
  if (commentBoxOpen) {
    return (
      <>
        <Box css={{ display: 'flex' }}>
          <TextField
            autoFocus
            variant="outlined"
            placeholder="Comment"
            className="comment-textfield"
            multiline
            rows={3}
            css={{ flex: 1, marginRight: '16px' }}
            onChange={(event) => {
              setAddedComment(event.target.value);
            }}
            inputProps={{ className: 'comment-textarea' }}
          />
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '8px',
            }}
          >
            <Button
              size="large"
              variant="contained"
              className="comment-save"
              css={{ marginBottom: '16px' }}
              disabled={addedComment === ''}
              onClick={() => handleSave()}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="comment-cancel"
              size="large"
              onClick={() => setCommentBoxOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <Divider orientation="horizontal" css={{ margin: '16px 0' }} />
      </>
    );
  }

  return null;
}

export default ActivityEventCommentBox;
