import { datadogLogs } from '@datadog/browser-logs';

export default function errorLogInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error?.response?.data?.detail) {
        datadogLogs.logger.error(error?.message, {}, error);
      }
      return Promise.reject(error);
    }
  );
}
