import { Box, TablePagination } from '@mui/material';
import styled from '@emotion/styled/macro';
import associateHeaderImage from '../../assets/images/associateImage.png';
import { theme } from '../../styles/theme';

export const AssociateDetailsInfo = styled.div`
  display: flex;
  margin-top: 16px;
  z-index: 3;
  position: relative;
`;

export const AssociateDetailsWrapper = styled.div`
  position: relative;
  padding: 16px 24px;
  background: linear-gradient(
    108deg,
    rgba(0, 144, 186, 0.04) 37.81%,
    #0090ba 144.67%
  );

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${associateHeaderImage}) no-repeat right;
    background-size: contain;
    z-index: 2;
  }
`;
export const AssociateInfoWrapper = styled.div`
  height: max-content;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AssociateLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 32px;
  min-width: 440px;
  max-width: 100%;
  box-sizing: border-box;
  border-right: 1px solid ${theme.palette.divider};
`;

export const AssociateActivityWrapper = styled(Box)`
  background-color: ${theme.palette.backgroundColor.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AssociateActivityHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.backgroundColor.secondary};
  padding: 18px 32px;
  border-bottom: 1px solid ${theme.palette.divider};
`;

export const AssociateActivityList = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
`;

export const ActivityPagination = styled(TablePagination)`
  && {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    min-height: 52px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${theme.palette.backgroundColor.secondary};
    overflow: hidden;
    padding-right: 30px;
    border-top: 1px solid ${theme.palette.divider};
    border-bottom: 1px solid ${theme.palette.divider};
  }
  .MuiToolbar-root {
    min-height: 52px !important;
  }
`;
export const ErrorWrapper = styled(Box)`
  && {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BlockSpan = styled.span`
  display: block;
`;
