/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BreadcrumbLink, BreadcrumbWrapper } from './style';

function Breadcrumb({ breadcrumbLinks, detailsText, cssOverride }) {
  return (
    <BreadcrumbWrapper css={cssOverride}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbLinks.map((breadcrumbLink, index) => (
          <BreadcrumbLink key={breadcrumbLink.href} href={breadcrumbLink.href}>
            {index === 0 && (
              <ArrowBackIcon
                css={{ height: '16px', width: '16px', marginRight: '8px' }}
              />
            )}
            {breadcrumbLink.linkText}
          </BreadcrumbLink>
        ))}
        <Typography variant="body1">{detailsText}</Typography>
      </Breadcrumbs>
    </BreadcrumbWrapper>
  );
}

export default Breadcrumb;
