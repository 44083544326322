import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import { URL } from '../../../core/config';

import * as actions from './actions';
import { abort } from '../abort/operations';

export const fetchJobDetails = (id) => async (dispatch, getState) => {
  const sectionKey = 'fetchJobDetails';

  await dispatch(abort(sectionKey));
  const {
    abort: { abortControllers },
  } = getState();
  const { signal } = abortControllers[sectionKey];
  const endpoint = URL.jobs;

  return HTTP.get(endpoint, {
    signal,
    params: { id },
  })
    .then((response) => {
      if (response.data.results.length === 1 && response.data.results[0]) {
        dispatch(actions.setJobDetails(response.data.results[0]));
      } else {
        throw new Error('Error while fetching job details');
      }
    })
    .catch((error) => {
      if (signal && !signal.aborted) {
        datadogLogs.logger.error(
          '[JobDetails] Error while fetching job details',
          {
            url: endpoint,
            error,
          }
        );
        throw error;
      }
    });
};
