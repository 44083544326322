import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingSpinner({ id }) {
  return (
    <Box
      id={id}
      css={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size="24px" />
    </Box>
  );
}

export default LoadingSpinner;
