import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  DesktopDateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { Box, Typography } from '@mui/material';
import { parse, format, isValid } from 'date-fns';
import { DATE_FNS_RANGE_FORMAT, DEFAULT_SELECTED_FILTERS } from '../constants';
import { updateSelectedFilterItems } from '../../../state/ducks/filters/actions';
import NumberOfSelectedFiltersTag from './NumberOfSelectedFiltersTag';

function DatesSelector() {
  const [errors, setErrors] = useState([null, null]);
  const dispatch = useDispatch();
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const getValue = (key) => {
    const selectedDate = Array.from(
      (isEmpty(selectedFilterItems[key])
        ? DEFAULT_SELECTED_FILTERS[key]
        : selectedFilterItems[key]
      ).values()
    )[0];

    return parse(selectedDate, DATE_FNS_RANGE_FORMAT, new Date());
  };
  const determineDate = (key, date) =>
    isValid(date)
      ? new Map([
          [
            format(date, DATE_FNS_RANGE_FORMAT),
            format(date, DATE_FNS_RANGE_FORMAT),
          ],
        ])
      : DEFAULT_SELECTED_FILTERS[key];

  const sectionKeys = ['activeRangeStart', 'activeRangeEnd'];

  function determineHelperText() {
    const startDateError = errors[0];
    const endDateError = errors[1];
    if (startDateError === 'invalidDate' && endDateError === 'invalidDate') {
      return 'Start date and end date are invalid';
    }
    if (startDateError === 'invalidDate') {
      return 'Start date is invalid';
    }
    if (endDateError === 'invalidDate') {
      return 'End date is invalid';
    }
    if (startDateError === 'invalidRange' && endDateError === 'invalidRange') {
      return 'Date range is invalid';
    }
    return '';
  }

  return (
    <Box css={{ padding: '16px' }} id="date-selector-section">
      <Box css={{ display: 'flex' }}>
        <Typography variant="subtitle1" css={{ marginBottom: '8px' }}>
          Date Range
        </Typography>
        <NumberOfSelectedFiltersTag sectionKeys={sectionKeys} />
      </Box>
      <DesktopDateRangePicker
        className="dates-selector"
        slots={{ field: SingleInputDateRangeField }}
        value={[getValue('activeRangeStart'), getValue('activeRangeEnd')]}
        onChange={(dates) => {
          dispatch(
            updateSelectedFilterItems({
              activeRangeStart: determineDate('activeRangeStart', dates[0]),
              activeRangeEnd: determineDate('activeRangeEnd', dates[1]),
            })
          );
        }}
        slotProps={{
          textField: {
            size: 'small',
            helperText: determineHelperText(),
          },
        }}
        css={{
          width: '100%',
        }}
        onError={(_errors) => {
          setErrors(_errors);
        }}
      />
    </Box>
  );
}

export default DatesSelector;
