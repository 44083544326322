/* eslint-disable camelcase */
import React from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { debounce } from '@mui/material/utils';
import { DesktopTimePicker } from '@mui/x-date-pickers-pro';
import { enqueueSnackbar } from 'notistack';
import { updateSchedule } from '../../../services/jobs';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import LoadingIndicator from './LoadingIndicator';
import { determinePickerSlotProps } from '../constants';
import { VARIANTS } from '../../../constants';

function JobTimePicker({
  value,
  id,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  storeTimezone,
  minDateTime,
  maxDateTime,
  setIsLoading,
  isLoading,
  setIsActive,
  setIsHovering,
  isPickerOpen,
  setIsPickerOpen,
}) {
  const handleTimeUpdate = (selectedDate) => {
    setIsLoading(true);
    updateSchedule(id, selectedDate)
      .then(() => {
        enqueueSnackbar('Time change saved successfully', {
          variant: VARIANTS.success,
        });
        getUpdatedJob(id);
        setIsLoading(false);
        setIsActive(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsActive(true);
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Something went wrong updating the job',
            error.response.data.detail,
            externalIdentifier,
            advantageSourceSystemName
          ),
          { variant: VARIANTS.error }
        );
      });
  };

  const handleInputChange = (selectedDate, context) => {
    if (!context.validationError && !isPickerOpen) {
      const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedTime = utcToZonedTime(selectedDate, systemTimezone);
      const timeToUTC = zonedTimeToUtc(zonedTime, storeTimezone);
      handleTimeUpdate(timeToUTC, false);
    }
  };

  const handleInputChangeDebounce = debounce(handleInputChange, 2000);

  return (
    <DesktopTimePicker
      label="Start Time"
      value={value}
      onOpen={() => {
        setIsPickerOpen(true);
        setIsActive(true);
      }}
      onClose={() => {
        setIsPickerOpen(false);
        setIsHovering(false);
      }}
      open={isPickerOpen}
      timezone={storeTimezone}
      minTime={minDateTime}
      maxTime={maxDateTime}
      disabled={isLoading}
      css={{
        width: '160px',
      }}
      slots={{
        openPickerIcon: isLoading ? LoadingIndicator : AccessTimeIcon,
      }}
      slotProps={determinePickerSlotProps(setIsActive)}
      onAccept={(selectedDate) => {
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedTime = utcToZonedTime(selectedDate, systemTimezone);
        const timeToUTC = zonedTimeToUtc(zonedTime, storeTimezone);
        handleTimeUpdate(timeToUTC, false);
      }}
      onChange={handleInputChangeDebounce}
      disableIgnoringDatePartForTimeValidation
    />
  );
}

export default JobTimePicker;
