import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { determineAssignmentsSelectedLabel } from '../utils';

function AssignmentSelected({ selectAllPagesLoading, countToDisplay }) {
  const batchJob = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  )[0];
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleModeOn = currentMode === 'single';
  if (isSingleModeOn) {
    return (
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
        id="assignment_id_and_source_system"
      >
        <Typography variant="subtitle3">Assignment ID</Typography>{' '}
        <Typography as="span" variant="body1">
          {batchJob &&
            `${batchJob.external_identifier} ${batchJob.advantage_source_system_name}`}
        </Typography>
      </Box>
    );
  }

  if (selectAllPagesLoading) {
    return (
      <Box
        id="batch_assignments_selected"
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <CircularProgress size="30px" />
        <Typography as="span" variant="subtitle3">
          Counting selected assignments
        </Typography>
      </Box>
    );
  }

  return (
    <div id="number_of_assignments_selected">
      <Typography as="span" variant="subtitle3">
        {countToDisplay}{' '}
      </Typography>
      <Typography as="span" id="assignments-selected-label">
        {determineAssignmentsSelectedLabel(countToDisplay)}
      </Typography>
    </div>
  );
}

export default AssignmentSelected;
