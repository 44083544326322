import * as React from 'react';
import { Box, SvgIcon } from '@mui/material';

export function ErrorWrapper({ icon, cssOverride, children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        ...cssOverride,
      }}
    >
      <SvgIcon
        sx={{
          width: 300,
          height: 'auto',
        }}
        component={icon}
        inheritViewBox
      />
      {children}
    </Box>
  );
}
