export const determineInitials = (name) => {
  if (name) {
    const firstLetters = name.match(/\b\w/g);
    const firstNameInitial = firstLetters[0];
    const lastIndex = firstLetters.length - 1;
    const lastNameInitial = firstLetters[lastIndex];
    return `${firstNameInitial}${lastNameInitial}`;
  }
  return '--';
};
