/* eslint-disable no-console */
import GA from './base';

export default class Memory extends GA {
  static load() {
    console.debug('Google Analytics Initilization : --');
  }

  static view = (path) => {
    console.debug(
      `Google Analytics Send pageView : ${
        path || window.location.pathname + window.location.search
      }`
    );
  };

  static event = (e, val) => {
    console.debug(`Google Analytics Send event : ${e} ${val || ''}`);
  };
}
