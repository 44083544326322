import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import {
  updateSelectedFilterItems,
  updateFilterQueries,
} from '../../../state/ducks/filters/actions';
import { DEFAULT_FILTER_QUERIES, RESET_SELECTED_FILTERS } from '../constants';
import { determineNumberOfFilters } from '../utils';

function ClearAllWrapper() {
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const numberOfFilters = determineNumberOfFilters(selectedFilterItems);
  const dispatch = useDispatch();
  const count = numberOfFilters > 0 ? ` (${numberOfFilters})` : '';

  return (
    <Box css={{ padding: '16px' }}>
      <Button
        variant="text"
        startIcon={<ClearAllIcon />}
        id="clear-all-filters-btn"
        onClick={() => {
          dispatch(updateSelectedFilterItems(RESET_SELECTED_FILTERS));
          dispatch(updateFilterQueries(DEFAULT_FILTER_QUERIES));
        }}
        disabled={numberOfFilters <= 0}
      >
        {`Reset all filters${count}`}
      </Button>
    </Box>
  );
}

export default ClearAllWrapper;
