import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import {
  CertificationName,
  CertificationsWrapper,
  NoResultsIconWrapper,
  NoResults,
  StyledAccordionSummary,
  StyledVerified,
} from '../style';
import { scrollIntoView } from '../../../lib/utils';

function CertificationsCollapsableHeader({ requiredCertifications }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    scrollIntoView(expanded, 'certifications-required-details');
  }, [expanded]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <StyledAccordionSummary
        id="certifications-header"
        expandIcon={<ExpandMoreIcon />}
      >
        Certifications Required
      </StyledAccordionSummary>
      <AccordionDetails id="certifications-required-details">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {requiredCertifications && requiredCertifications.length > 0 ? (
              <>
                {requiredCertifications.map((certification, index) => (
                  <CertificationsWrapper key={certification.id}>
                    <StyledVerified fontSize="large" />
                    <CertificationName id={`certification-${index}`}>
                      {certification.name}
                    </CertificationName>
                  </CertificationsWrapper>
                ))}
              </>
            ) : (
              <>
                <NoResultsIconWrapper>
                  <StyledVerified fontSize="large" />
                </NoResultsIconWrapper>
                <NoResults id="no-certifications">
                  No certifications required
                </NoResults>
              </>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default CertificationsCollapsableHeader;
