import { VIEWS, URL } from '../../config';
import auth from '../../../services/auth';
import * as index from '../../../index';

let isAlreadyFetchingAccessToken = false;
let requestQueue = [];

const sendQueuedRequests = () => {
  requestQueue.map((cb) => cb());
  requestQueue = [];
};

export default function authorizationRefreshInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;
      if (response) {
        const { status, request } = response;
        const originalConfig = config;

        if (status === 401) {
          if (request.responseURL.includes(URL.tokenRefresh)) {
            requestQueue = [];
            await index.router.navigate(VIEWS.public.logout);
            return Promise.reject(error);
          }

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            auth
              .refreshAccessToken()
              .then(() => {
                sendQueuedRequests();
              })
              .catch(async () => {
                await index.router.navigate(VIEWS.public.logout);
              })
              .finally(() => {
                isAlreadyFetchingAccessToken = false;
              });
          }

          const requestSubscribers = new Promise((resolve) => {
            requestQueue.push(() => {
              resolve(axiosInstance(originalConfig));
            });
          });

          return requestSubscribers;
        }
        return Promise.reject(error);
      }
      return Promise.resolve();
    }
  );
}
