import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';

export const AssociateHeaderWrapper = styled(Box)`
  padding: 16px 40px 16px 40px;
  background-color: white;
  border-bottom: 1px solid #061a2a1f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
