import React from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { determineSaveButtonText } from '../utils';

function Submission({ disabled, handleClose, handleSave, loading }) {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  return (
    <Box
      css={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
      }}
    >
      <Button id="cancel_btn" variant="text" onClick={handleClose}>
        Cancel
      </Button>
      <LoadingButton
        id="batch_edit_save"
        variant="contained"
        loading={loading}
        disabled={disabled}
        onClick={handleSave}
      >
        {determineSaveButtonText[currentMode]}
      </LoadingButton>
    </Box>
  );
}

export default Submission;
