import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  AccessTimeOutlined,
  CheckCircleOutlined,
  AutoAwesomeOutlined,
} from '@mui/icons-material';

export const determineBatchStatus = ({
  processedDate,
  totalRows,
  successfulRows,
  failedRows,
  staffedRows,
  notStaffedRows,
}) => {
  if (
    processedDate &&
    (totalRows === successfulRows || totalRows === staffedRows + notStaffedRows)
  ) {
    return 'COMPLETED_SUCCESSFULLY';
  }

  if (processedDate && failedRows > 0) {
    return 'COMPLETED_WITH_ERRORS';
  }

  return 'PROCESSING';
};

export const determineIconColor = (status, theme) => {
  switch (status) {
    case 'COMPLETED_SUCCESSFULLY':
      return theme.palette.success.main;
    case 'COMPLETED_WITH_ERRORS':
      return theme.palette.warning.main;
    default:
      return theme.palette.info.main;
  }
};

const getIconProps = ({
  color,
  marginRight = '8px',
  width = '24px',
  height = '24px',
}) => ({
  css: { color, marginRight, width, height },
});

export const determineBatchIcon = (status, theme, mode, width, height) => {
  const color = determineIconColor(status, theme, mode);
  const iconProps = getIconProps({ color, width, height });

  if (mode === 'bulk') {
    switch (status) {
      case 'COMPLETED_SUCCESSFULLY':
        return <CheckCircleOutlined {...iconProps} />;
      case 'COMPLETED_WITH_ERRORS':
        return <ErrorOutlineOutlinedIcon {...iconProps} />;
      default:
        return <AccessTimeOutlined {...iconProps} />;
    }
  }

  return <AutoAwesomeOutlined {...iconProps} />;
};

export const determineBatchState = {
  PROCESSING: 'Started',
  COMPLETED_SUCCESSFULLY: 'Completed',
  COMPLETED_WITH_ERRORS: 'Completed',
};

export const determineBatchId = {
  PROCESSING: 'in_progress_batch',
  COMPLETED_SUCCESSFULLY: 'successful_batch',
  COMPLETED_WITH_ERRORS: 'failed_batch',
};
