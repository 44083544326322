import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';

function Help() {
  const theme = useTheme();
  const maxWidth = '960px';
  return (
    <Box
      css={{
        backgroundColor: theme.palette.backgroundColor.primary,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Header maxWidth={maxWidth} />
      <Content maxWidth={maxWidth} />
      <Footer maxWidth={maxWidth} />
    </Box>
  );
}

export default Help;
