import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer({ maxWidth }) {
  return (
    <Box
      css={{
        marginTop: '52px',
        marginBottom: '52px',
        textAlign: 'center',
        maxWidth,
        padding: '0 24px',
      }}
    >
      <Typography as="h2" variant="h6" css={{ marginBottom: '24px' }}>
        Need to talk to someone directly?
      </Typography>
      <Typography variant="body2">
        Call the Boost Helpdesk at
        <Typography as="span" variant="subtitle1">
          {' 1-888-900-4276 '}
        </Typography>
        for any questions or technical issues with Boost.
      </Typography>
      <Typography variant="body2">
        Monday - Friday 6:00 am - 7:00 pm CT
      </Typography>
    </Box>
  );
}

export default Footer;
