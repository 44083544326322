export const statusAttributesMapping = {
  urgent: { color: 'warning', label: 'Urgent' },
  past_due: { color: 'info', label: 'Past Due' },
  confirmation_requested: { color: 'secondary', label: 'Offered' },
  jyver_confirmed: { color: 'success', label: 'Confirmed' },
  auto_confirmed: { color: 'success', label: 'Confirmed' },
  jyver_replied_no: { color: 'error', label: 'Declined' },
};

export const determinePickerSlotProps = (setIsActive) => ({
  textField: {
    size: 'small',
    sx: {
      svg: { width: '24px', height: '24px' },
    },
  },
  field: {
    InputProps: {
      onFocus: () => {
        setIsActive(true);
      },
    },
  },
});
