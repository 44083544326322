import { getAssociate } from 'services/user';

export const handleSelectOption = (associate, setSelectedOptions) => {
  setSelectedOptions((prevOptions) => {
    const isOptionSelected = prevOptions.some(({ id }) => id === associate.id);
    return isOptionSelected
      ? prevOptions.filter(({ id }) => id !== associate.id)
      : [...prevOptions, associate];
  });
};

export const determineAssociateRequest = (inputValue) => {
  const key = Number.isNaN(parseFloat(inputValue)) ? 'fullName' : 'externalId';
  return { [key]: inputValue };
};

export const getAssociatesDebounce = async (inputValue, callback) => {
  const request = determineAssociateRequest(inputValue);
  const associates = await getAssociate(request);
  return callback(
    associates.map((associate) => ({
      label: associate.full_name,
      id: associate.id,
      employeeId: associate.external_id,
    }))
  );
};
