import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchAssociate from 'components/JobTable/components/SearchAssociate';
import auth from 'services/auth';
import { updateSelectedManager } from 'state/ducks/manager/actions';

export default function ManagerSelect({ setPage }) {
  const dispatch = useDispatch();
  const currentUser = auth.getCurrentUser();
  const { fullName } = useSelector((state) => state.manager);

  const onChange = (manager) => {
    setPage(0);
    dispatch(
      updateSelectedManager({ id: manager.id, fullName: manager.label })
    );
  };

  const onClear = () => {
    dispatch(updateSelectedManager(currentUser));
    setPage(0);
  };

  return (
    <SearchAssociate
      sx={{
        minWidth: '240px',
        paddingTop: '5px',
      }}
      label="Search Manager / Staffer"
      noOptionsText="No Manager / Staffer found."
      value={fullName}
      onChange={onChange}
      shouldStopDisable
      id="manager-select"
      onClear={onClear}
    />
  );
}
