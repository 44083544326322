import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  determineDateDistance,
  formatRecentActivityDate,
} from 'components/SuggestAssociates/utils';

export default function AssociateRecentActivity({ lastActiveDate }) {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <Typography variant="body1">
        {`${determineDateDistance(lastActiveDate)}\u00A0`}
      </Typography>
      <Typography css={{ color: secondary }}>
        {formatRecentActivityDate(lastActiveDate)}
      </Typography>
    </Box>
  );
}
