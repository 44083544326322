import React from 'react';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format, utcToZonedTime } from 'date-fns-tz';
import auth from 'services/auth';
import {
  determineBatchIcon,
  determineBatchStatus,
} from 'containers/ViewBatchDrawer/utils';
import { objectKeysSnakeToCamel } from 'services/case';
import { BatchDetailsHeaderWrapper } from '../style';

function BatchDetailsHeader({ batchDetails, isLoading }) {
  const theme = useTheme();
  const { palette } = theme;

  const { timezone } = auth.getCurrentUser();

  const batchData = objectKeysSnakeToCamel(batchDetails);

  const status = determineBatchStatus(batchData);

  const iconWidth = '35px';
  const iconHeight = '35px';

  return (
    <BatchDetailsHeaderWrapper>
      {isLoading ? (
        <Skeleton variant="rectangular" width={35} height={35} />
      ) : (
        batchDetails.batch_type === 'auto' &&
        determineBatchIcon(
          status,
          theme,
          batchDetails.batch_type,
          iconWidth,
          iconHeight
        )
      )}
      <Box>
        <Typography id="batch-name" variant="h6" color={palette.text.primary}>
          {isLoading ? (
            <Skeleton variant="h6" width={376} height={28} />
          ) : (
            batchDetails.name && `${batchDetails.name} Details`
          )}
        </Typography>
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          {isLoading ? (
            <Skeleton
              variant="body2"
              width={254}
              height={20}
              css={{ marginTop: '4px' }}
            />
          ) : (
            <>
              <Typography
                variant="body2"
                id="batch-id"
                color={palette.text.secondary}
              >{`ID: ${batchDetails.id}`}</Typography>
              <Divider
                orientation="vertical"
                color={palette.text.secondary}
                css={{ height: '12px', margin: '0 8px' }}
              />
              <Typography
                id="batch-date"
                variant="body2"
                color={palette.text.secondary}
              >
                {batchDetails.processed_date &&
                  `Completed ${format(
                    utcToZonedTime(batchDetails.processed_date, timezone),
                    'MMMM d, yyyy - hh:mm a (z)',
                    {
                      timeZone: timezone,
                    }
                  )}`}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </BatchDetailsHeaderWrapper>
  );
}

export default BatchDetailsHeader;
