import ReactGA from 'react-ga';
import { GA_ID } from '../../core/config';

class GA {
  static load() {
    ReactGA.initialize(GA_ID);
  }

  /*
   * GA PageView
   * */
  static view = (path) => {
    ReactGA.pageview(path || window.location.pathname + window.location.search);
  };

  /*
   * GA Event
   * */
  static event = (e, val) => {
    if (val) {
      const event = GA.listOfEvents[e];
      event.value = val;
      return ReactGA.event(event);
    }
    return ReactGA.event(GA.listOfEvents[e]);
  };
}

GA.PageLoaded = 'PageLoaded';
GA.ReviewDurationInSeconds = 'ReviewDuration';
GA.listOfEvents = {
  [GA.PageLoaded]: {
    category: 'X',
    action: 'Page Loaded',
  },
  [GA.ReviewDurationInSeconds]: {
    category: 'Review',
    action: 'ReviewDuration',
    value: 0,
  },
};

export default GA;
