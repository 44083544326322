import React from 'react';
import { Box } from '@mui/system';
import { Checkbox } from '@mui/material';

function ActivityEventCheckbox({ isRead, id, onChange }) {
  return (
    <Box
      css={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        background: `${isRead ? '#F5F5F5' : 'rgba(255, 121, 64, 0.12)'}`,
      }}
    >
      <Checkbox
        size="small"
        id={`activity-checkbox-${id}`}
        className="activity-checkbox"
        checked={isRead}
        onChange={onChange}
      />
    </Box>
  );
}

export default ActivityEventCheckbox;
