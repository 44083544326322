/* ACTIONS */

import * as types from './types';

export const setShowFilters = (isOpen) => ({
  type: types.SET_SHOW_FILTERS,
  payload: isOpen,
});

export const updateSelectedFilterItems = (selectedFilterItems) => ({
  type: types.UPDATE_SELECTED_FILTERS,
  payload: selectedFilterItems,
});

export const updateFilterItems = (filterItems) => ({
  type: types.UPDATE_FILTER_ITEMS,
  payload: filterItems,
});

export const updateFilterQueries = (filterQueries) => ({
  type: types.UPDATE_FILTER_QUERIES,
  payload: filterQueries,
});
