import * as types from './types';

export const setBatch = (batch) => ({
  type: types.SET_BATCH,
  payload: batch,
});

export const setBatchDetails = (batches) => ({
  type: types.SET_BATCH_DETAILS,
  payload: batches,
});

export const updateRowsPerPage = (rowsPerPage) => ({
  type: types.UPDATE_BATCH_DETAILS_ROWS_PER_PAGE,
  payload: rowsPerPage,
});
