import Cookies from 'js-cookie';

import { CSRF_TOKEN_COOKIE_KEY } from '../../config';

export default function csrfTokenInterceptor(axiosInstance) {
  return axiosInstance.interceptors.request.use((config) => {
    const mutatedConfig = Object.assign({}, config);
    const { method } = mutatedConfig;

    if (['post', 'delete', 'put'].includes(method)) {
      const csrfToken = Cookies.get(CSRF_TOKEN_COOKIE_KEY);

      if (csrfToken) {
        mutatedConfig.headers['X-CSRFToken'] = csrfToken;
      }
    }

    return mutatedConfig;
  });
}
