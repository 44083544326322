import * as React from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import { ExpandMore } from '@mui/icons-material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  differenceInMilliseconds,
  formatDuration,
  intervalToDuration,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import {
  getAssociateSchedule,
  getAssociateTimeOff,
} from '../../../services/user';
import { BlackSquare } from '../style';
import Error from './Error';
import { standardDateFnsFormat } from '../../../services/date';
import { secondaryColor } from '../../../styles/theme/colors';
import { defaultColor } from '../../../styles/theme';
import LoadingSpinner from './LoadingSpinner';

function ScheduleCollapsableHeader({ associate, job, selectedDay }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTimeOff, setIsLoadingTimeOff] = useState(false);
  const [unavailableTimes, setUnavailableTimes] = useState([]);
  const [overallTimeUnavailable, setOverallTimeUnavailable] = useState([]);
  const [timeOff, setTimeOff] = useState([]);
  const [unavailableTimesError, setUnavailableTimesError] = useState(null);
  const [timeOffError, setTimeOffError] = useState(null);

  useEffect(() => {
    const mergedArrays = unavailableTimes.concat(timeOff);

    mergedArrays.sort(
      (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
    );

    setOverallTimeUnavailable(mergedArrays);
  }, [unavailableTimes, timeOff]);

  const handleDayChange = (date) => {
    setIsLoading(true);
    if (date) {
      setOverallTimeUnavailable([]);
      setUnavailableTimes([]);
      setTimeOff([]);
      const effectiveDate = zonedTimeToUtc(
        startOfDay(date),
        job.store_location_timezone
      );
      getAssociateSchedule({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          setIsLoading(false);
          const addedDateTimeList = results.map((result) => ({
            start_datetime: result.job_start_after,
            datetime: result.job_start_after,
            end_datetime: result.job_finish_before,
            store_location_timezone: result.store_location_timezone,
            isOvernight: !isSameDay(
              utcToZonedTime(
                new Date(result.job_start_after),
                job.store_location_timezone
              ),
              utcToZonedTime(
                new Date(result.job_finish_before),
                job.store_location_timezone
              )
            ),
            ...result,
          }));

          setUnavailableTimes(addedDateTimeList);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!axios.isCancel(error)) {
            setUnavailableTimesError(
              error.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setUnavailableTimesError(error);
        });
      setIsLoadingTimeOff(true);
      getAssociateTimeOff({
        userId: associate.id,
        effectiveDate,
        storeTimezone: job.store_location_timezone,
      })
        .then((results) => {
          setIsLoadingTimeOff(false);
          const addedDateTimeList = results.map((result) => ({
            datetime: result.start_datetime,
            ...result,
          }));
          setTimeOff(addedDateTimeList);
        })
        .catch((error) => {
          setIsLoadingTimeOff(false);
          if (!axios.isCancel(error)) {
            setTimeOffError(
              error.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setTimeOffError(error);
        });
    }
  };

  useEffect(() => {
    handleDayChange(selectedDay);
  }, [selectedDay]);

  const calculateTimeFrame = (finishBeforeDate, startAfterDate) => {
    const beforeDate = new Date(finishBeforeDate);
    const afterDate = new Date(startAfterDate);

    const durationInMilliseconds = differenceInMilliseconds(
      beforeDate,
      afterDate
    );
    const duration = intervalToDuration({
      start: 0,
      end: durationInMilliseconds,
    });
    const formattedDuration = formatDuration(duration, {
      format: ['days', 'hours', 'minutes'],
    });

    return (
      <Typography color={secondaryColor}>{`${formattedDuration}`}</Typography>
    );
  };

  const determineTimeOff = (startTime, endTime) => {
    const startTimeWithTimezone = utcToZonedTime(
      new Date(startTime),
      job.store_location_timezone
    );
    const endTimeWithTimezone = utcToZonedTime(
      new Date(endTime),
      job.store_location_timezone
    );
    const timezoneAbr = format(startTimeWithTimezone, 'zzz', {
      timeZone: job.store_location_timezone,
    });
    const isAllDay =
      endTimeWithTimezone.getTime() - startTimeWithTimezone.getTime() >=
      24 * 60 * 60 * 1000;

    if (isAllDay) {
      return <Typography variant="subtitle2">All Day</Typography>;
    }

    return (
      <Typography variant="body2">
        {format(startTimeWithTimezone, 'h:mm a')}
        {' - '}
        {format(endTimeWithTimezone, 'h:mm a')}
        {` (${timezoneAbr})`}
      </Typography>
    );
  };

  const formatTimezone = (timezone, utcDateTime) => {
    if (timezone) {
      const zonedDateTime = utcToZonedTime(utcDateTime, timezone);
      const offsetString = format(zonedDateTime, 'O', { timeZone: timezone });
      const zoneAbbr = format(zonedDateTime, 'zzz', { timeZone: timezone });
      const zoneFullName = timezone.split('/')[1].replace('_', ' ');

      return `(${offsetString}:00) ${zoneAbbr} - ${zoneFullName}`;
    }
    return '';
  };

  const loading = isLoading || isLoadingTimeOff;

  return (
    <Accordion defaultExpanded square>
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box>
          <Typography variant="subtitle1">Work Schedule</Typography>
          <Typography variant="body2" id="selected-day">
            {format(selectedDay, 'MMMM do, yyyy, EEEE', {
              timeZone: job.store_location_timezone,
            })}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loading && <LoadingSpinner id="associate-schedule-loading" />}
        {(unavailableTimesError || timeOffError) && !loading ? (
          <Error
            id="associate-schedule-error"
            doRetry={() => {
              setUnavailableTimesError(null);
              setTimeOffError(null);
              handleDayChange(selectedDay);
            }}
          />
        ) : (
          <>
            {overallTimeUnavailable.map((unavailableTime, index) => (
              <Box
                id={`rounded-box-${index}`}
                key={unavailableTime.datetime}
                css={{
                  borderRadius: '10px',
                  border: '1px solid #c6cace',
                  padding: '10px',
                  margin: '12px',
                  backgroundColor: defaultColor,
                }}
              >
                {unavailableTime.job_start_after ? (
                  <>
                    {unavailableTime.isOvernight && (
                      <>
                        <Box css={{ display: 'inline-flex' }}>
                          <ErrorOutlineOutlinedIcon color="error" />
                          <Typography
                            css={{
                              fontWeight: 'bolder',
                              color: 'red',
                              marginLeft: '10px',
                            }}
                          >
                            Overnight Work
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {'Starts '}
                            <BlackSquare />{' '}
                            {format(
                              utcToZonedTime(
                                new Date(unavailableTime.job_start_after),
                                job.store_location_timezone
                              ),
                              'eee, MMM d',
                              { timeZone: job.store_location_timezone }
                            )}
                          </Typography>
                        </Box>
                      </>
                    )}
                    <div>
                      <Typography variant="subtitle2">
                        {`${format(
                          utcToZonedTime(
                            new Date(unavailableTime.job_start_after),
                            job.store_location_timezone
                          ),
                          'h:mm a',
                          { timeZone: job.store_location_timezone }
                        )} - ${format(
                          utcToZonedTime(
                            new Date(unavailableTime.job_finish_before),
                            job.store_location_timezone
                          ),
                          'h:mm a',
                          { timeZone: job.store_location_timezone }
                        )}`}
                      </Typography>
                    </div>
                    <Typography variant="subtitle2">
                      {formatTimezone(
                        job.store_location_timezone,
                        unavailableTime.job_start_after
                      )}
                    </Typography>
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '4px 8px',
                        backgroundColor: '#0b3b6004',
                        borderRadius: '4px',
                        width: 'fit-content',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                      }}
                    >
                      {calculateTimeFrame(
                        unavailableTime.job_finish_before,
                        unavailableTime.job_start_after
                      )}
                    </Box>
                    <Typography variant="body2">
                      {unavailableTime.external_project_identifier}
                      {' - '}
                      {unavailableTime.name} - {unavailableTime.banner_name} (
                      {unavailableTime.store_location_self_identity})
                    </Typography>
                    <Typography variant="body2">
                      {unavailableTime.store_location_address.address}
                    </Typography>
                    <Typography variant="body2">
                      {unavailableTime.store_location_address.city},{' '}
                      {unavailableTime.store_location_address.state}{' '}
                      {unavailableTime.store_location_address.postal_code}
                    </Typography>
                    <Box
                      css={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="body2">
                        {`Earliest Start: ${format(
                          utcToZonedTime(
                            new Date(unavailableTime.created_job_start_after),
                            job.store_location_timezone
                          ),
                          standardDateFnsFormat,
                          { timeZone: job.store_location_timezone }
                        )}`}
                      </Typography>
                      <Typography variant="body2">
                        {`Latest Finish: ${format(
                          utcToZonedTime(
                            new Date(unavailableTime.created_job_finish_before),
                            job.store_location_timezone
                          ),
                          standardDateFnsFormat,
                          { timeZone: job.store_location_timezone }
                        )}`}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      {determineTimeOff(
                        unavailableTime.start_datetime,
                        unavailableTime.end_datetime
                      )}
                    </Box>
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '4px 8px',
                        backgroundColor: '#0b3b6004',
                        borderRadius: '4px',
                        width: 'fit-content',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                      }}
                    >
                      {calculateTimeFrame(
                        unavailableTime.end_datetime,
                        unavailableTime.start_datetime
                      )}
                    </Box>
                    <Typography variant="body2">Time Off</Typography>
                  </>
                )}
              </Box>
            ))}
            {overallTimeUnavailable.length === 0 && !loading ? (
              <Typography
                variant="subtitle2"
                id="no-work-div"
                css={{
                  justifyContent: 'center',
                  display: 'flex',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                No scheduled work for this day
              </Typography>
            ) : null}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default ScheduleCollapsableHeader;
