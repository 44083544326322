import { isArray } from 'lodash';
import store from 'store2';
import { FILTERS_CACHE_KEY } from './constants';

export const clearFiltersIfOutOfDate = () => {
  const cachedFilters = store.get(FILTERS_CACHE_KEY);
  if (cachedFilters) {
    const notAllCacheValuesAreArray = !Object.values(cachedFilters).some(
      (value) => isArray(value)
    );
    if (notAllCacheValuesAreArray) {
      store.set(FILTERS_CACHE_KEY, {});
    }
  }
};
