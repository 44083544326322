/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import JobTimeDrawerEditing from './JobTimeDrawerEditing';
import JobTimeInlineEditing from './JobTimeInlineEditing';

function JobTimeInfo({
  id,
  status,
  startDate,
  endDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  eta,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  job,
}) {
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const isSingleEditModeEnabled = featureFlags[flagName];

  if (isSingleEditModeEnabled) {
    return <JobTimeDrawerEditing job={job} />;
  }

  return (
    <JobTimeInlineEditing
      id={id}
      status={status}
      startDate={startDate}
      endDate={endDate}
      storeTimezone={storeTimezone}
      createdJobStartAfter={createdJobStartAfter}
      createdJobFinishBefore={createdJobFinishBefore}
      eta={eta}
      advantageSourceSystemName={advantageSourceSystemName}
      externalIdentifier={externalIdentifier}
      getUpdatedJob={getUpdatedJob}
    />
  );
}

export default JobTimeInfo;
