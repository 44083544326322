/* eslint-disable camelcase,react/no-array-index-key */

import React from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import JobStatusTag from '../../../components/JobTable/components/JobStatusTag';
import JobStatusChip from '../../../components/JobTable/components/JobStatusChip';

function Header({
  job: {
    id,
    name,
    banner_name,
    store_location_self_identity,
    store_location_address: { address, city, state, postal_code },
    status_central,
    status_attributes,
    confirmation_status,
  },
  onClose,
}) {
  const banner = `${banner_name} ${
    store_location_self_identity ? `(${store_location_self_identity})` : ''
  }`;

  return (
    <Box css={{ margin: '16px' }}>
      <Stack
        direction="row"
        alignItems="flex-start"
        css={{
          marginBottom: '16px',
          justifyContent: 'space-between',
          columnGap: '16px',
        }}
        spacing={1}
      >
        <Box
          display="flex"
          css={{ columnGap: '16px', alignItems: 'center', flexGrow: '1' }}
        >
          <Typography variant="h5" css={{ fontWeight: '600' }}>
            {name}
          </Typography>
          <JobStatusTag status={status_central} />
          {status_attributes.map((attribute, index) => (
            <JobStatusChip
              jobId={id}
              jobDetails
              variant="filled"
              status={attribute}
              key={`${attribute}-${index}`}
            />
          ))}
          {confirmation_status && (
            <JobStatusChip
              jobId={id}
              jobDetails
              status={confirmation_status}
              variant="filled"
            />
          )}
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="h6">{banner}</Typography>
      <Typography variant="body2">
        {address} {city}, {state}, {postal_code}
      </Typography>
    </Box>
  );
}

export default Header;
