import React from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchInput({ value, onChange, isLoading, isDisabled, sectionKey }) {
  return (
    <TextField
      label="Search..."
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? <CircularProgress size={20} /> : <SearchIcon />}
          </InputAdornment>
        ),
      }}
      size="small"
      css={{
        width: '100%',
      }}
      id={`filter-section-${sectionKey}-search-input`}
      disabled={isDisabled}
    />
  );
}

export default SearchInput;
