import * as types from './types';

const coreReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    isMainMenuOpen: false,
    numberOfUnreadItems: 0,
  },
  action
) => {
  switch (action.type) {
    case types.SET_SHOW_MAIN_MENU:
      return {
        ...state,
        isMainMenuOpen: action.payload,
      };
    case types.UPDATE_UNREAD_ITEMS:
      return {
        ...state,
        numberOfUnreadItems: action.payload,
      };
    default:
      return state;
  }
};

export default coreReducer;
