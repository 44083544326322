import React from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format, utcToZonedTime } from 'date-fns-tz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import auth from 'services/auth';
import { useNavigate } from 'react-router-dom';
import {
  determineBatchStatus,
  determineBatchId,
  determineBatchState,
  determineBatchIcon,
} from '../utils';
import BatchRow from './BatchRow';
import { BatchAccordion } from '../style';

function Batch({ batch }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { timezone } = auth.getCurrentUser();
  const status = determineBatchStatus({
    processedDate: batch.processed_date,
    totalRows: batch.total_rows,
    successfulRows: batch.successful_rows,
    failedRows: batch.failed_rows,
    staffedRows: batch.staffed_rows,
    notStaffedRows: batch.not_staffed_rows,
  });

  const showCompletedBatchDetails =
    status === 'COMPLETED_SUCCESSFULLY' || status === 'COMPLETED_WITH_ERRORS';

  return (
    <BatchAccordion id={`${determineBatchId[status]}_${batch.id}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {determineBatchIcon(status, theme, batch.batch_type)}
        <Typography variant="subtitle1" id="batch_name">
          {batch.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          css={{
            padding: '16px',
            backgroundColor: '#FAFAFA',
          }}
        >
          <Typography
            variant="body2"
            id="batch_id"
          >{`ID: ${batch.id}`}</Typography>
          <Typography variant="body2" id="batch_status">
            {`${determineBatchState[status]} ${format(
              utcToZonedTime(
                status === 'PROCESSING'
                  ? batch.created_date
                  : batch.processed_date,
                timezone
              ),
              'MMMM d, yyyy - hh:mm a (z)',
              {
                timeZone: timezone,
              }
            )}`}
          </Typography>
          <Box
            css={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
            id="batch_total_rows"
          >
            <Typography variant="subtitle3">
              Total assignments in batch:
            </Typography>
            <Typography variant="subtitle3">{batch.total_rows}</Typography>
          </Box>
          {showCompletedBatchDetails && (
            <>
              <Divider css={{ marginTop: '8px' }} />
              {batch.successful_rows > 0 && (
                <BatchRow
                  id="batch_successful_rows"
                  title="Completed Successfully"
                  value={batch.successful_rows}
                />
              )}
              {batch.staffed_rows > 0 && (
                <BatchRow
                  id="batch_staffed_rows"
                  title="Staffed"
                  value={batch.staffed_rows}
                />
              )}
              {batch.not_staffed_rows > 0 && (
                <BatchRow
                  id="batch_not_staffed_rows"
                  title="Not Staffed"
                  value={batch.not_staffed_rows}
                />
              )}
              {status === 'COMPLETED_WITH_ERRORS' && (
                <>
                  <BatchRow
                    id="batch_failed_rows"
                    title="Failed due to errors"
                    value={batch.failed_rows}
                    color={theme.palette.error.main}
                  />
                  {batch.unknown_rows > 0 && (
                    <BatchRow
                      id="batch_unknown_rows"
                      title="Unknown"
                      value={batch.unknown_rows}
                    />
                  )}
                </>
              )}
              <Button
                id="batch_view_details_btn"
                variant="outlined"
                onClick={() => {
                  navigate(`/batch-details/${batch.id}`);
                }}
              >
                View details
              </Button>
            </>
          )}
        </Box>
      </AccordionDetails>
    </BatchAccordion>
  );
}

export default Batch;
