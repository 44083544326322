import React from 'react';
import { Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { ContactActionButtonsWrapper, TooltipMessage } from '../style';
import { determineAddress } from '../utils';
import AssociateContactButton from './AssociateContactButton';

function AssociateContactActions({ email, phoneNumber, profile }) {
  const determineContactTooltipMessage = (label, message) => (
    <Box>
      <TooltipMessage>{label}</TooltipMessage>
      {label === 'Address' ? (
        <>
          <TooltipMessage>{message.address}</TooltipMessage>
          <TooltipMessage>
            {determineAddress(message.city, message.state, message.postal_code)}
          </TooltipMessage>
        </>
      ) : (
        <TooltipMessage>{message}</TooltipMessage>
      )}
    </Box>
  );

  return (
    <ContactActionButtonsWrapper>
      <AssociateContactButton
        id="associate_email_action"
        label="Email"
        tooltipMessage={determineContactTooltipMessage('Email', email)}
        disabled={!email}
        Icon={MailOutlineIcon}
        href={`mailto:${email}`}
      />
      <AssociateContactButton
        id="associate_phone_action"
        label="Phone"
        tooltipMessage={determineContactTooltipMessage('Phone', phoneNumber)}
        disabled={!phoneNumber}
        Icon={LocalPhoneOutlinedIcon}
        href={`tel:${phoneNumber}`}
      />

      <AssociateContactButton
        id="associate_address_action"
        label="Address"
        tooltipMessage={determineContactTooltipMessage('Address', profile)}
        disabled={!profile.city && !profile.state && !profile.postal_code}
        Icon={LocationOnOutlinedIcon}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          determineAddress(profile.city, profile.state, profile.postal_code)
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      />
    </ContactActionButtonsWrapper>
  );
}

export default AssociateContactActions;
