/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import JobDateDrawerEditing from './JobDateDrawerEditing';
import JobDateInlineEditing from './JobDateInlineEditing';

function JobDateInfo({
  id,
  status,
  startDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  job,
}) {
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const isSingleEditModeEnabled = featureFlags[flagName];
  if (isSingleEditModeEnabled) {
    return <JobDateDrawerEditing job={job} />;
  }

  return (
    <JobDateInlineEditing
      id={id}
      status={status}
      startDate={startDate}
      storeTimezone={storeTimezone}
      createdJobStartAfter={createdJobStartAfter}
      createdJobFinishBefore={createdJobFinishBefore}
      advantageSourceSystemName={advantageSourceSystemName}
      externalIdentifier={externalIdentifier}
      getUpdatedJob={getUpdatedJob}
    />
  );
}

export default JobDateInfo;
