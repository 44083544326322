export const DEFAULT_VIEW_ASSOCIATE = {
  city: '',
  department: '',
  exceptions: [],
  first_name: '',
  last_name: '',
  miles_from_job: '',
  phone_number: '',
  postal_code: '',
  remaining_hours: '',
  state: '',
  title: '',
  user_id: '',
  fromJobDetails: false,
  hideAssignButton: false,
  hideMatchDetails: false,
};
