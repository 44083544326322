import { combineReducers } from 'redux';
import * as types from './types';
import {
  DEFAULT_ALERT,
  DEFAULT_AUTO_STAFFING,
  DEFAULT_BATCH,
} from './constants';

// eslint-disable-next-line default-param-last
const alertReducer = (state = DEFAULT_ALERT, { type, payload }) => {
  switch (type) {
    case types.SET_ALERT: {
      return payload;
    }
    default:
      return state;
  }
};

const batchReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_BATCH,
  { type, payload }
) => {
  switch (type) {
    case types.SET_BATCH: {
      return payload;
    }
    default:
      return state;
  }
};

const autoStaffingReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_AUTO_STAFFING,
  { type, payload }
) => {
  switch (type) {
    case types.SET_AUTO_STAFFING: {
      return payload;
    }
    default:
      return state;
  }
};

export default combineReducers({
  alert: alertReducer,
  batch: batchReducer,
  autoStaffing: autoStaffingReducer,
});
