import React, { useState } from 'react';

import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as operations from 'state/ducks/jobDetails/operations';
import JobDetailsDrawer from '../../../containers/JobDetailsDrawer';
import { DEFAULT_VIEW_ASSOCIATE } from '../../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';

function JobID({
  jobId,
  externalIdentifier,
  advantageSourceSystemName,
  dataDogActionName,
}) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };
  const jobDetails = useSelector((state) => state.jobDetails);

  const fetchJobDetails = (id) => {
    setIsLoading(true);

    dispatch(operations.fetchJobDetails(id))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsErrored(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        component="button"
        onClick={() => {
          setOpen(true);
          fetchJobDetails(jobId);
          dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
          dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
        }}
        className="job-reference-id"
        sx={{
          textAlign: 'left',
        }}
        data-dd-action-name={dataDogActionName}
      >
        <Box>{externalIdentifier}</Box>

        {advantageSourceSystemName && (
          <Box className="advantage_source_system_name">
            {advantageSourceSystemName}
          </Box>
        )}
      </Link>
      <JobDetailsDrawer
        isLoading={isLoading}
        isErrored={isErrored}
        job={jobDetails}
        open={open}
        onClose={onClose}
      />
    </>
  );
}

export default JobID;
