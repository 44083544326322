import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AssociateInfoWrapper,
  AssociateLeftColumn,
} from 'containers/Associate/style';
import { AvailabilityTypography } from '../style';
import AssociateAvailabilityInfoRow from '../AssociateAvailabilityInfoRow';

function SkeletonAssociateWorkPreferences() {
  const { palette } = useTheme();

  return (
    <AssociateInfoWrapper className="associate-work-preferences">
      <AssociateLeftColumn>
        <>
          <Box
            css={{
              width: '100%',
              borderBottom: `1px solid ${palette.divider}`,
              paddingBottom: '8px',
            }}
            id="associate-daily-availability"
          >
            <AvailabilityTypography variant="subtitle1">
              Availability
            </AvailabilityTypography>

            <AssociateAvailabilityInfoRow label="Home store only">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Willing to drive">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Travel and stay overnight">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Preferred hours a week">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Maximum hours per day">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Sunday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Monday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Tuesday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Wednesday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Thursday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Friday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Saturday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
          </Box>
          <Box
            css={{
              width: '100%',
              borderBottom: `1px solid ${palette.divider}`,
              paddingBottom: '8px',
            }}
            id="associate-daily-availability"
          >
            <AvailabilityTypography variant="subtitle1">
              Future Availability
            </AvailabilityTypography>

            <AssociateAvailabilityInfoRow label="Home store only">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Willing to drive">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Travel and stay overnight">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Preferred hours a week">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Maximum hours per day">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>

            <AssociateAvailabilityInfoRow label="Sunday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Monday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Tuesday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Wednesday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Thursday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Friday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
            <AssociateAvailabilityInfoRow label="Saturday">
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '40%',
                }}
              />
            </AssociateAvailabilityInfoRow>
          </Box>
          <Box
            css={{
              width: '100%',
              borderBottom: `1px solid ${palette.divider}`,
              paddingBottom: '8px',
            }}
          >
            <AvailabilityTypography variant="subtitle1">
              Time-off Request
            </AvailabilityTypography>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                margin: '8px 0 ',
              }}
            >
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '100%',
                }}
              />
              <Skeleton
                variant="text"
                css={{
                  fontSize: '14px',
                  width: 100,
                }}
              />
              <Skeleton
                variant="text"
                css={{
                  fontSize: '16px',
                  width: '100%',
                }}
              />
              <Skeleton
                variant="text"
                css={{
                  fontSize: '14px',
                  width: 100,
                }}
              />
            </Box>
          </Box>
        </>
      </AssociateLeftColumn>
      <Box
        css={{
          backgroundColor: palette.backgroundColor.primary,
          width: '100%',
        }}
      />
    </AssociateInfoWrapper>
  );
}

export default SkeletonAssociateWorkPreferences;
