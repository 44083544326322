import styled from '@emotion/styled/macro';
import { Accordion, TablePagination } from '@mui/material';
import { theme } from '../../styles/theme';

export const ViewBatchPagination = styled(TablePagination)`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    overflow: hidden;
    min-height: 64px;
    height: 64px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-top: 1px solid ${theme.palette.divider};
  }
`;

export const BatchAccordion = styled(Accordion)`
  padding-right: 8px;

  :last-of-type {
    box-shadow: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
`;
