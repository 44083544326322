import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { removeNonNumeric } from '../../../services/text';
import { determineColor, validateTime } from '../utils';

function DurationEdit({
  durationEdit,
  setDurationEdit,
  isLoading,
  durationView,
  selectedDurationHours,
  setSelectedDurationHours,
  selectedDurationMinutes,
  setSelectedDurationMinutes,
  isEditable,
  isDurationEmpty,
  setIsDurationValid,
}) {
  const color = determineColor(isEditable);
  const maxHoursAllowed = 23;
  const maxMinutesAllowed = 59;
  const durationEmptyError = isDurationEmpty && 'Minutes are required';
  const [hoursOverLimit, setHoursOverLimit] = useState('');
  const [minutesOverLimit, setMinutesOverLimit] = useState('');

  const validateHours = (hourInput) => validateTime(hourInput, maxHoursAllowed);

  const validateMinutes = (minuteInput) =>
    validateTime(minuteInput, maxMinutesAllowed);

  useEffect(() => {
    if (durationEdit) {
      const isValidHours = validateHours(selectedDurationHours);
      const isValidMinutes = validateMinutes(selectedDurationMinutes);
      setHoursOverLimit(!isValidHours ? `Max value of ${maxHoursAllowed}` : '');
      setMinutesOverLimit(
        !isValidMinutes ? `Max value of ${maxMinutesAllowed}` : ''
      );
      setIsDurationValid(isValidHours && isValidMinutes && !isDurationEmpty);
    }
  }, [selectedDurationHours, selectedDurationMinutes, durationEdit]);

  const handleHoursChange = (event) => {
    const hours = removeNonNumeric(event.target.value);
    setSelectedDurationHours(hours);
  };

  const handleMinutesChange = (event) => {
    const minutes = removeNonNumeric(event.target.value);
    setSelectedDurationMinutes(minutes);
  };

  if (durationEdit) {
    return (
      <>
        <Typography
          variant="body2"
          css={{ paddingTop: '16px', paddingBottom: '12px' }}
        >
          Duration
        </Typography>
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              autoFocus
              id="batch_edit_duration_hours_picker"
              type="text"
              label="Hours"
              disabled={isLoading}
              variant="outlined"
              value={selectedDurationHours}
              onChange={handleHoursChange}
              onFocus={(event) => {
                event.target.select();
              }}
              error={hoursOverLimit}
              helperText={hoursOverLimit}
            />
            <Divider
              orientation="horizontal"
              css={{
                width: '16px',
                marginLeft: '8px',
                marginRight: '8px',
              }}
            />
          </Box>
          <TextField
            id="batch_edit_duration_minutes_picker"
            type="text"
            label="Minutes"
            variant="outlined"
            disabled={isLoading}
            value={selectedDurationMinutes}
            onChange={handleMinutesChange}
            error={minutesOverLimit || isDurationEmpty}
            helperText={minutesOverLimit || durationEmptyError}
          />
        </Box>
      </>
    );
  }

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography id="current_duration_label" variant="subtitle3" css={color}>
          Duration
        </Typography>
        <Typography id="current_duration" variant="body1" css={color}>
          {durationView}
        </Typography>
      </Box>
      {isEditable && (
        <Button
          id="batch_edit_duration"
          variant="text"
          onClick={() => setDurationEdit(true)}
          disabled={isLoading}
        >
          Edit
        </Button>
      )}
    </Box>
  );
}

export default DurationEdit;
