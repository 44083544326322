import { useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import auth from '../../services/auth';

function Logout(props) {
  const { oktaAuth } = props;
  useEffect(() => {
    oktaAuth.signOut();
    auth.logout();
  }, [oktaAuth]);

  return null;
}

export default withOktaAuth(Logout);
