import * as types from './types';

export const setEventLogs = (eventLogs) => ({
  type: types.SET_EVENT_LOGS,
  payload: eventLogs,
});

export const setRowsPerPage = (rowsPerPage) => ({
  type: types.SET_ROWS_PER_PAGE,
  payload: rowsPerPage,
});
