import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addDays, format, subDays } from 'date-fns';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { secondaryColor } from '../../../styles/theme/colors';
import { defaultColor } from '../../../styles/theme';

function MonthAndWeekdaySelector(props) {
  const { selectedDay, setSelectedDay } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [buttonVariant] = useState([
    'contained',
    'outlined',
    'outlined',
    'outlined',
    'outlined',
  ]);

  const handleDateChange = (date) => {
    setIsOpen(false);
    setSelectedDay(date);
  };

  const field = ({ InputProps }) => (
    <Box css={{ marginLeft: '8px' }}>
      <ArrowDropDownIcon
        css={{ marginTop: '8px' }}
        ref={InputProps.ref}
        onClick={() => setIsOpen(true)}
      />
    </Box>
  );
  return (
    <Box
      css={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: defaultColor,
        borderTop: 'outset',
      }}
    >
      <Box css={{ width: '100%', display: 'flex', marginBottom: '24px' }}>
        <Box css={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" id="month-span">
            {format(selectedDay, 'MMMM')}
          </Typography>
          <DatePicker
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={(value) => handleDateChange(value)}
            format="MMMM"
            value={selectedDay}
            slotProps={{
              field: {
                InputProps: {
                  ref: null,
                },
              },
            }}
            slots={{
              field,
            }}
          />
        </Box>
        <Box
          css={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            id="back-five-days"
            onClick={() => {
              handleDateChange(subDays(new Date(selectedDay), 5));
            }}
          >
            <ArrowBackIos fontSize="small" css={{ color: 'black' }} />
          </Button>
          <Button
            id="forward-five-days"
            onClick={() => {
              handleDateChange(addDays(new Date(selectedDay), 5));
            }}
          >
            <ArrowForwardIos fontSize="small" css={{ color: 'black' }} />
          </Button>
        </Box>
      </Box>
      <Stack
        direction="row"
        css={{ width: '100%', justifyContent: 'space-evenly', display: 'flex' }}
      >
        {buttonVariant.map((variant, index) => {
          const newDate = addDays(new Date(selectedDay), index);
          const day = format(newDate, 'eee');
          const date = newDate.getDate();
          const isContained = variant === 'contained';
          const color = isContained ? defaultColor : '#061A2A99';
          const backgroundColor = isContained ? secondaryColor : defaultColor;
          const borderColor = '#061a2a3b';
          const fontColor = isContained ? defaultColor : '#061A2ADE';
          return (
            <Button
              css={{
                color,
                backgroundColor,
                borderColor,
                width: '90px',
                margin: '0 4px',
              }}
              key={date}
              id={`date-btn-${index}`}
              onClick={() => {
                handleDateChange(addDays(new Date(selectedDay), index));
              }}
              variant={variant}
            >
              <Box>
                <Typography>{day}</Typography>
                <Typography variant="subtitle1" css={{ color: fontColor }}>
                  {date}
                </Typography>
              </Box>
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
}

export default MonthAndWeekdaySelector;
