/* REDUCERS */

import * as types from './types';

const abortReducer = (
  // eslint-disable-next-line default-param-last
  state = { abortControllers: {} },
  action
) => {
  switch (action.type) {
    case types.UPDATE_ABORT_CONTROLLER_RESOURCE:
      return {
        ...state,
        abortControllers: {
          ...state.abortControllers,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default abortReducer;
