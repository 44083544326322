import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import * as types from './types';

const selectedManagerReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    id: null,
    fullName: null,
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_SELECTED_MANAGER: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default persistReducer(
  {
    key: 'manager',
    storage,
  },
  selectedManagerReducer
);
