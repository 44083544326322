import * as types from './types';

export const setShowMainMenu = (isOpen) => ({
  type: types.SET_SHOW_MAIN_MENU,
  payload: isOpen,
});

export const updateNumberOfUnreadItems = (numberOfUnreadItems) => ({
  type: types.UPDATE_UNREAD_ITEMS,
  payload: numberOfUnreadItems,
});
