import * as actions from './actions';

export const abort = (resource) => async (dispatch, getState) => {
  const {
    abort: { abortControllers },
  } = getState();
  const abortController = abortControllers[resource];
  if (abortController) {
    abortController.abort();
  }
  const newAbortController = new AbortController();
  return dispatch(
    actions.updateAbortController({ [resource]: newAbortController })
  );
};
