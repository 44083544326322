import styled from '@emotion/styled/macro';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Verified } from '@mui/icons-material';
import { secondaryColor } from '../../styles/theme/colors';
import {
  primaryFont,
  primaryFontLineHeight,
  primaryFontSize,
  primaryFontWeight,
  secondaryFont,
  secondaryFontLineHeight,
  secondaryFontSize,
  secondaryFontWeight,
} from '../../styles/theme/fonts';

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: ${primaryFontWeight};
  line-height: 175%;
  letter-spacing: 0.15px;
`;

export const StyledGridValue = styled(Grid)`
  font-family: ${secondaryFont};
  font-style: normal;
  font-size: ${secondaryFontSize};
  line-height: ${secondaryFontLineHeight};
  letter-spacing: 0.1px;
`;

export const StyledGridLabel = styled(StyledGridValue)`
  font-family: ${primaryFont};
  font-weight: ${primaryFontWeight};
`;

export const AssociateName = styled(Link)`
  cursor: pointer;
  && {
    color: black;
    margin-bottom: 8px;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: ${secondaryFontSize};
    line-height: ${primaryFontLineHeight};
    letter-spacing: 0.1px;
    font-weight: ${primaryFontWeight};
  }
`;

export const AssociateDetail = styled(Grid)`
  font-family: ${secondaryFont};
  font-style: normal;
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
  line-height: ${secondaryFontLineHeight};
  letter-spacing: 0.17px;
  margin-bottom: 2px;
`;

export const StyledEmailLink = styled(Link)`
  && {
    color: ${secondaryColor};
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: ${secondaryFontWeight};
    font-size: ${secondaryFontSize};
    line-height: 175%;
  }
`;

export const StyledPhoneNumber = styled(AssociateDetail)`
  color: #061a2a99;
`;

export const NoAssociate = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: ${primaryFontWeight};
  font-size: ${primaryFontSize};
  line-height: 157%;
  text-align: center;
  letter-spacing: 0.1px;
  color: #061a2ade;
  margin-bottom: 8px;
`;

export const NoAssociateInfo = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: ${secondaryFont};
  font-style: normal;
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-bottom: 22px;
`;

export const SuggestButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SuggestButton = styled(Button)`
  && {
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: ${primaryFontWeight};
    font-size: ${secondaryFontSize};
    line-height: ${secondaryFontLineHeight};
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
`;

export const NoResultsIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
`;

export const StyledDescriptionIcon = styled(DescriptionIcon)`
  color: #479c6b;
  height: 33px;
  width: 27px;
  && {
    font-size: 2.5em;
  }
`;

export const NoResults = styled.span`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: ${primaryFontWeight};
  font-size: ${secondaryFontSize};
  line-height: 157%;
  text-align: center;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledAttachmentLink = styled(Link)`
  && {
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: ${secondaryFontWeight};
    font-size: ${secondaryFontSize};
    line-height: 143%;
    letter-spacing: 0.17px;
    margin-left: 14px;
    color: ${secondaryColor};
  }
`;

export const AttachmentsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CertificationsWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid darkgrey;
  padding: 10px;
  margin: 12px;
  background-color: white;
  display: flex;
  align-items: center;
`;

export const CertificationName = styled.span`
  margin-left: 16px;
  font-family: ${secondaryFont};
  font-style: normal;
  font-weight: ${secondaryFontWeight};
  font-size: ${secondaryFontSize};
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(6, 26, 42, 0.87);
`;

export const StyledVerified = styled(Verified)`
  && {
    color: #479c6b;
  }
`;
