import * as types from './types';

export const setIsAuthorized = (isAuthorized) => ({
  type: types.SET_IS_AUTHORIZED,
  payload: isAuthorized,
});

export const setGroups = (groups) => ({
  type: types.SET_GROUPS,
  payload: groups,
});
