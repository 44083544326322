import { Button } from '@mui/material';
import { getTransitionStyles, filterDrawerWidth } from 'styles/theme';
import styled from '@emotion/styled/macro';

const marginRight = filterDrawerWidth;
export const ViewBatchesButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'isOpen',
})(({ isOpen }) => ({
  ...getTransitionStyles(isOpen, marginRight),
}));
