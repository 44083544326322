import React from 'react';
import { Box, Typography } from '@mui/material';

function BatchRow({ id, title, value, color }) {
  return (
    <Box
      css={{
        marginBottom: '8px',
        marginTop: '8px',
        justifyContent: 'space-between',
        display: 'flex',
      }}
      id={id}
    >
      <Typography variant="body1" color={color}>
        {title}
      </Typography>
      <Typography variant="subtitle3" color={color}>
        {value}
      </Typography>
    </Box>
  );
}

export default BatchRow;
