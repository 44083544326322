/* eslint-disable camelcase */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import AsyncAutocomplete from '../../Select/AsyncAutocomplete';
import { getAssociatesDebounce } from '../../AssociateTable/utils';

function SearchAssociate({
  value,
  onChange,
  isActive,
  sx,
  disabled,
  id,
  shouldStopDisable = false,
  shouldClearOnChange = false,
  label = 'Search Associate',
  noOptionsText = 'No Associate Found',
  onClear,
  onFocus,
  autoFocus = false,
  size = 'small',
}) {
  return (
    <AsyncAutocomplete
      autoFocus={autoFocus}
      label={label}
      noOptionsText={noOptionsText}
      sx={sx}
      id={id}
      shouldStopDisable={shouldStopDisable}
      value={value}
      shouldClearOnChange={shouldClearOnChange}
      onChange={onChange}
      size={size}
      getResourcesDebounce={(inputValue, callback) =>
        getAssociatesDebounce(inputValue, callback)
      }
      onClear={onClear}
      onFocus={onFocus}
      isActive={isActive}
      disabled={disabled}
      renderOption={(props, option) => (
        <li {...props} key={option.employeeId}>
          <Grid>
            <Typography variant="body2">{option.label}</Typography>
            {option.employeeId && (
              <Typography variant="body2" color="text.secondary">
                {option.employeeId}
              </Typography>
            )}
          </Grid>
        </li>
      )}
    />
  );
}

export default SearchAssociate;
