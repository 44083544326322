import styled from '@emotion/styled/macro';
import { Link } from '@mui/material';
import { theme } from '../../styles/theme';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
`;

export const BreadcrumbLink = styled(Link)`
  && {
    display: flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
    text-decoration-color: ${theme.palette.primary.main};
    line-height: 24px;
  }
`;
