import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import { setFeatureFlags } from './actions';
import { URL } from '../../../core/config';

export const mapFeatureToggles = (featureToggles) =>
  featureToggles.reduce(
    (accumulator, featureToggle) => ({
      ...accumulator,
      [featureToggle.name]: featureToggle.is_enabled,
    }),
    {}
  );

export const containsFeatureFlags = (flagNames, featureFlags) =>
  flagNames.every((flagName) => Object.keys(featureFlags).includes(flagName));

export const fetchFeatureFlags = (flagNames) => async (dispatch, getState) => {
  const { featureFlags } = getState();
  if (!containsFeatureFlags(flagNames, featureFlags)) {
    try {
      const params = flagNames
        .map((flagName) => `flag_name=${flagName}`)
        .join('&');
      const response = await HTTP.get(`${URL.featureFlags}?${params}`);
      if (response?.data) {
        dispatch(setFeatureFlags(mapFeatureToggles(response.data)));
      } else {
        throw new Error('response data does not exist');
      }
    } catch (error) {
      datadogLogs.logger.error('Unable to get feature flags', {}, error);
    }
  }
};
