import * as types from './types';

export const setAssociates = (associates) => ({
  type: types.SET_ASSOCIATES,
  payload: associates,
});

export const updateRowsPerPage = (rowsPerPage) => ({
  type: types.UPDATE_ROWS_PER_PAGE,
  payload: rowsPerPage,
});
