import { combineReducers } from 'redux';
import store from 'store2';
import { isEmpty } from 'lodash';
import {
  DEFAULT_FILTER_QUERIES,
  DEFAULT_SELECTED_FILTERS,
} from '../../../containers/Filters/constants';

import * as types from './types';
import { FILTERS_CACHE_KEY } from './constants';
import { clearFiltersIfOutOfDate } from './migrate';

const visibilityReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    isOpen: false,
  },
  action
) => {
  switch (action.type) {
    case types.SET_SHOW_FILTERS: {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    default:
      return state;
  }
};

const determineDefaultFilters = () => {
  clearFiltersIfOutOfDate();
  const cachedFilters = store.get(FILTERS_CACHE_KEY) || {};
  return Object.keys(DEFAULT_SELECTED_FILTERS).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: !isEmpty(cachedFilters[key])
        ? new Map(cachedFilters[key])
        : DEFAULT_SELECTED_FILTERS[key],
    }),
    {}
  );
};

const selectedFilterItemsReducer = (
  // eslint-disable-next-line default-param-last
  state = determineDefaultFilters(),
  action
) => {
  switch (action.type) {
    case types.UPDATE_SELECTED_FILTERS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const filterItemsReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_SELECTED_FILTERS,
  action
) => {
  switch (action.type) {
    case types.UPDATE_FILTER_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const filterQueriesReducer = (
  // eslint-disable-next-line default-param-last
  state = DEFAULT_FILTER_QUERIES,
  action
) => {
  switch (action.type) {
    case types.UPDATE_FILTER_QUERIES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  selectedFilterItems: selectedFilterItemsReducer,
  filterItems: filterItemsReducer,
  filterQueries: filterQueriesReducer,
  visibility: visibilityReducer,
});
