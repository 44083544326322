import React from 'react';

import { Typography } from '@mui/material';
import ErrorPage from '../../components/ErrorPage';
import { ReactComponent as ErrorIcon } from '../../assets/empty-states/5xx-error.svg';

function Error() {
  return (
    <ErrorPage icon={ErrorIcon}>
      <Typography id="error-message" variant="h6">
        Whoopsie Daisy!
      </Typography>
      <Typography id="error-description" variant="body1">
        Something that should never happen seems to have happened and we&apos;re
        terribly sorry.
      </Typography>
    </ErrorPage>
  );
}

export default Error;
