import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import {
  AttachmentsWrapper,
  NoResultsIconWrapper,
  NoResults,
  StyledAccordionSummary,
  StyledAttachmentLink,
  StyledDescriptionIcon,
} from '../style';
import { scrollIntoView } from '../../../lib/utils';

function AttachmentsCollapsableHeader({ attachmentsUrl }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    scrollIntoView(expanded, 'scrollToDiv');
  }, [expanded]);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <StyledAccordionSummary
        id="attachments-header"
        expandIcon={<ExpandMoreIcon />}
      >
        Attachments
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {attachmentsUrl ? (
              <AttachmentsWrapper>
                <AttachFileIcon />
                <StyledAttachmentLink
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  href={attachmentsUrl}
                  id="attachment-link"
                >
                  {attachmentsUrl}
                </StyledAttachmentLink>
              </AttachmentsWrapper>
            ) : (
              <>
                <NoResultsIconWrapper>
                  <StyledDescriptionIcon />
                </NoResultsIconWrapper>
                <NoResults id="no-files-attached">
                  Please find documents in the system which this work is
                  originated
                </NoResults>
              </>
            )}
          </Grid>
        </Grid>
        <div id="scrollToDiv" />
      </AccordionDetails>
    </Accordion>
  );
}

export default AttachmentsCollapsableHeader;
