import { datadogLogs } from '@datadog/browser-logs';
import { updateNumberOfUnreadItems } from './actions';
import { getUnreadAssociateAvailabilityNumber } from '../../../services/user';

export const getNumberOfUnreadAvailabilityChanges =
  (managerId) => async (dispatch) => {
    try {
      const response = await getUnreadAssociateAvailabilityNumber(managerId);
      dispatch(updateNumberOfUnreadItems(response.total_unread_count));
    } catch (error) {
      datadogLogs.logger.error(
        `Unable to get unread activity for manager: ${managerId}`,
        {},
        error
      );
    }
  };
