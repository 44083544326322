/* eslint-disable camelcase */
import React from 'react';

import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { transformTimeOffData } from 'lib/utils';
import { AvailabilityTypography, NoResults } from '../style';

function AssociateUnavailableTimes({ unavailable_times, associateTimezone }) {
  const { palette } = useTheme();

  if (unavailable_times.length === 0) {
    return (
      <Box
        css={{
          width: '100%',
          paddingBottom: '8px',
        }}
      >
        <AvailabilityTypography variant="subtitle1">
          Time-off Request
        </AvailabilityTypography>
        <NoResults id="unavailability-time-no-result" variant="body1">
          No time off request submitted
        </NoResults>
      </Box>
    );
  }

  return (
    <Box
      css={{
        width: '100%',
        paddingBottom: '8px',
      }}
    >
      <AvailabilityTypography variant="subtitle1">
        Time-off Request
      </AvailabilityTypography>

      {unavailable_times.map((time) => (
        <Box
          key={time.id}
          id={time.id}
          css={{
            display: 'flex',
            flexDirection: 'column',
            margin: '8px 0 ',
          }}
        >
          <AvailabilityTypography variant="body1">
            {transformTimeOffData(
              time.start_datetime,
              time.end_datetime,
              associateTimezone
            )}
          </AvailabilityTypography>
          <Typography
            variant="body2"
            css={{ color: palette.text.secondary, overflowWrap: 'break-word' }}
          >
            {time.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default AssociateUnavailableTimes;
