import React from 'react';
import { Link, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { secondaryFont } from 'styles/theme';
import { AssociateContactInfoRowWrapper, NoResults } from '../style';

function AssociateContactInfoRow({
  id,
  href,
  hasInfo,
  message,
  label,
  type,
  children,
  isLoading,
}) {
  const { palette } = useTheme();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Skeleton
          variant="text"
          css={{
            fontSize: '16px',
            marginBottom: '16px',
            width: '100%',
          }}
        />
      );
    }

    if (!hasInfo) {
      return (
        <NoResults id={`${id}-no-result`} variant="body1">
          {message}
        </NoResults>
      );
    }

    if (type === 'email') {
      return (
        <Link
          variant="body1"
          css={{
            color: palette.secondary.main,
            marginBottom: '16px',
            textDecorationColor: palette.secondary.main,
          }}
          href={href}
          id={id}
        >
          {children}
        </Link>
      );
    }

    return (
      <Typography
        id={id}
        variant="body1"
        css={{
          marginBottom: '16px',
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <AssociateContactInfoRowWrapper>
      <Typography
        variant="subtitle3"
        css={{
          marginBottom: '8px',
          color: palette.text.primary,
          fontFamily: secondaryFont,
        }}
      >
        {label}
      </Typography>
      {renderContent()}
    </AssociateContactInfoRowWrapper>
  );
}

export default AssociateContactInfoRow;
