import * as types from './types';

const featureFlagsReducer = (
  // eslint-disable-next-line default-param-last
  state = {},
  action
) => {
  switch (action.type) {
    case types.SET_FEATURE_FLAGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default featureFlagsReducer;
