import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function Error({ doRetry, id }) {
  return (
    <Box
      id={id}
      css={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        css={{
          width: '400px',
          textAlign: 'center',
          color: '#D32F2F',
        }}
        variant="subtitle1"
      >
        There was an error retrieving data.
      </Typography>
      <Button
        variant="outlined"
        className="button"
        css={{ marginTop: '8px' }}
        onClick={doRetry}
        id="associate-details-refresh-btn"
      >
        Refresh
      </Button>
    </Box>
  );
}

export default Error;
