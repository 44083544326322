import store from 'store2';

const removeAllExceptFilter = (cacheStartsWithKeys, filterFunction) => {
  store
    .keys()
    .filter(filterFunction)
    .forEach((filterKey) => {
      store.remove(filterKey);
    });
};

const startsWithKey = (keys, filterKey) =>
  keys.some((key) => filterKey.startsWith(key));

export const removeAllFromCacheThatStartWith = (cacheStartsWithKeys) => {
  removeAllExceptFilter(cacheStartsWithKeys, (filterKey) =>
    startsWithKey(cacheStartsWithKeys, filterKey)
  );
};

export const removeAllFromCacheExceptStartsWith = (cacheStartsWithKeys) => {
  removeAllExceptFilter(
    cacheStartsWithKeys,
    (filterKey) => !startsWithKey(cacheStartsWithKeys, filterKey)
  );
};
