import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import { secondaryColor } from '../../../../styles/theme/colors';
import {
  primaryFontWeight,
  secondaryFont,
} from '../../../../styles/theme/fonts';
import FilterAccordionSummary from '../FilterAccordionSummary';
import { updateSelectedFilterItems } from '../../../../state/ducks/filters/actions';

function TextareaFilterSection({ sectionKey, title }) {
  const reduxTextareaValue = Array.from(
    useSelector(
      (state) => state.filters.selectedFilterItems[sectionKey]
    ).values()
  ).join('\r\n');
  const [textareaValue, setTextareaValue] = useState(reduxTextareaValue);
  const [lastTextareaValue, setLastTextareaValue] =
    useState(reduxTextareaValue);
  const dispatch = useDispatch();
  const formatFiltersForComparison = (filters) =>
    filters
      ? filters
          .trim()
          .split(/\r?\n|\r|\n/g)
          .map((value) => value.trim())
          .join()
      : '';

  useEffect(() => {
    setTextareaValue(reduxTextareaValue);
    setLastTextareaValue(reduxTextareaValue);
  }, [reduxTextareaValue === '']);

  return (
    <Accordion>
      <FilterAccordionSummary title={title} sectionKey={sectionKey} />
      <AccordionDetails>
        <TextField
          label={`Paste or type one ${title} per line`}
          value={textareaValue}
          onChange={({ target: { value } }) => {
            setTextareaValue(value);
          }}
          multiline
          rows={6}
          fullWidth
          id={`filter-section-${sectionKey}-textarea-input`}
        />
        <Button
          onClick={() => {
            const trimmedActualTextareaValue = textareaValue.trim();
            dispatch(
              updateSelectedFilterItems({
                [sectionKey]: new Map(
                  trimmedActualTextareaValue === ''
                    ? []
                    : trimmedActualTextareaValue
                        .split(/\r?\n|\r|\n/g)
                        .filter((value) => value)
                        .map((value) => value.trim())
                        .map((value) => [value, value])
                ),
              })
            );
            setLastTextareaValue(trimmedActualTextareaValue);
          }}
          disabled={
            formatFiltersForComparison(textareaValue) ===
            formatFiltersForComparison(lastTextareaValue)
          }
          variant="outlined"
          id={`filter-section-${sectionKey}-search-button`}
          sx={{
            border: `1px solid ${secondaryColor}`,
            color: secondaryColor,
            fontFamily: secondaryFont,
            fontWeight: primaryFontWeight,
            marginTop: '15px',
          }}
        >
          SEARCH
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}

export default TextareaFilterSection;
