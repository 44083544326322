/* eslint-disable camelcase */
import { isAfter } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

export function hasAddressInfo(associateProfile) {
  if (!associateProfile) return false;
  return (
    associateProfile.address ||
    associateProfile.city ||
    associateProfile.state ||
    associateProfile.postal_code
  );
}

export function displayAvailability(availability) {
  return availability ? 'Yes' : 'No';
}

export function displayCommuteMiles(maximumCommuteMiles) {
  if (maximumCommuteMiles === 0) return 'Unlimited';
  if (!maximumCommuteMiles) return '0';
  return `${maximumCommuteMiles} miles`;
}

export function displayMaxDailyHours(preferred_daily_hours) {
  return preferred_daily_hours >= 24
    ? 'No limit'
    : `${preferred_daily_hours} hours`;
}

export function getAvailabilityStatus(
  actual_effective_date,
  associateTimeZone
) {
  const actualEffectiveDate = utcToZonedTime(
    new Date(actual_effective_date),
    associateTimeZone
  );

  const dateToCompare = utcToZonedTime(new Date(), associateTimeZone);

  if (isAfter(actualEffectiveDate, dateToCompare)) {
    return `FUTURE AVAILABILITY${
      actualEffectiveDate &&
      `: ${format(new Date(actualEffectiveDate), 'MM/dd/yyyy')}`
    }
    `;
  }
  return `AVAILABILITY`;
}

export function formatChangedDate(created_at, associateTimeZone) {
  const formattedDate = created_at
    ? format(
        utcToZonedTime(new Date(created_at), associateTimeZone),
        'MMMM dd, yyyy | h:mm a z',
        {
          timeZone: associateTimeZone,
          locale: enUS,
        }
      )
    : '';
  return formattedDate;
}

export function addCommentToEventLogs(eventLogId, eventLogs, comment) {
  return eventLogs.map((eventLog) =>
    eventLog.id === eventLogId
      ? {
          ...eventLog,
          comments: [comment, ...eventLog.comments],
        }
      : eventLog
  );
}

export const displayHomeStore = (home_store_location) => {
  const primarySelfIdentity = home_store_location.primary_self_identity
    ? ` - ${home_store_location.primary_self_identity}`
    : '';

  return `${home_store_location.chain_name}${primarySelfIdentity}`;
};
