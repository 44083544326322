export default function errorDetailInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error?.response?.data?.detail) {
        const modifiedError = {
          ...(error || {}),
          response: {
            ...(error?.response || {}),
            data: {
              ...(error?.response?.data || {}),
              detail: '',
            },
          },
        };
        return Promise.reject(modifiedError);
      }
      return Promise.reject(error);
    }
  );
}
