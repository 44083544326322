import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Header({ maxWidth }) {
  const theme = useTheme();
  return (
    <Box
      css={{
        alignItems: 'center',
        backgroundColor: theme.palette.backgroundColor.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        css={{
          boxSizing: 'border-box',
          maxWidth,
          padding: '0 24px',
          textAlign: 'center',
        }}
      >
        <Typography
          id="title"
          css={{ marginBottom: '24px', marginTop: '42px' }}
          variant="h4"
        >
          Boost Help Center
        </Typography>
        <Typography
          variant="body1"
          css={{
            marginBottom: '42px',
            textAlign: 'center',
          }}
        >
          Welcome to Boost&apos;s Help Center, your go-to resource for all the
          information you need to make the most of our products and services.
          We&apos;re delighted to have you here!
        </Typography>
      </Box>
    </Box>
  );
}

export default Header;
