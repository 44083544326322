import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

export default function PreferenceItem({
  title,
  newAvailability,
  oldAvailability,
  operation,
  displayFunction,
  eventPreferenceId,
  titleId,
}) {
  const { palette } = useTheme();
  return (
    <Box css={{ display: 'flex', flexDirection: 'column' }}>
      <Typography id={titleId} variant="subtitle3" color={palette.text.primary}>
        {title}
      </Typography>
      {operation === 'UPDATE' ? (
        <Box className="updated-event-preferences" css={{ display: 'flex' }}>
          <Typography variant="body1">
            New: {displayFunction(newAvailability)}
          </Typography>
          <Divider
            orientation="vertical"
            css={{ margin: '0 8px', color: palette.divider }}
          />
          <Typography variant="body1" color={palette.text.secondary}>
            Old: {displayFunction(oldAvailability)}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1" id={eventPreferenceId}>
          {displayFunction(newAvailability)}
        </Typography>
      )}
    </Box>
  );
}
