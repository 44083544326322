import React from 'react';
import { Alert } from '@mui/material';
import { VARIANTS } from '../../../constants';

const determineHomeSystem = (batchJobs) => {
  const distinctHomeSystems = [
    ...new Set(
      batchJobs.map((batchJob) => batchJob.advantage_source_system_name)
    ),
  ];
  const distinctHomeSystemWithoutJet = distinctHomeSystems.filter(
    (homeSystem) => homeSystem !== 'JET'
  );
  return distinctHomeSystemWithoutJet[0] || 'Agency';
};

function DoesNotAllowDateTimeAndDurationEditAlert({
  isDateTimeDurationEditable,
  batchJobs,
}) {
  if (isDateTimeDurationEditable) {
    return null;
  }

  const homeSystem = determineHomeSystem(batchJobs);
  return (
    <Alert
      id="does-not-allow-date-time-and-duration-edit-alert"
      severity={VARIANTS.info}
      variant="filled"
      css={{
        marginTop: '24px',
        color: '#013654',
        background: '#E6F3FA',
      }}
    >
      {`${homeSystem} work does not allow Date, Start Time and Duration changes.`}
    </Alert>
  );
}

export default DoesNotAllowDateTimeAndDurationEditAlert;
