/* eslint-disable camelcase */
import React from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { debounce } from '@mui/material/utils';
import EventIcon from '@mui/icons-material/Event';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { enqueueSnackbar } from 'notistack';
import LoadingIndicator from './LoadingIndicator';
import { updateSchedule } from '../../../services/jobs';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { determinePickerSlotProps } from '../constants';
import { VARIANTS } from '../../../constants';

function JobDatePicker({
  value,
  maxDateTime,
  minDateTime,
  id,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  storeTimezone,
  setIsLoading,
  isLoading,
  setIsActive,
  setIsHovering,
  isPickerOpen,
  setIsPickerOpen,
}) {
  const handleDateUpdate = (selectedDate) => {
    setIsLoading(true);

    updateSchedule(id, selectedDate)
      .then(() => {
        enqueueSnackbar('Date change saved successfully', {
          variant: VARIANTS.success,
        });
        getUpdatedJob(id);
        setIsLoading(false);
        setIsActive(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsActive(true);
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Something went wrong updating the job',
            error.response.data.detail,
            externalIdentifier,
            advantageSourceSystemName
          ),
          { variant: VARIANTS.error }
        );
      });
  };

  const handleInputChange = (selectedDate, context) => {
    if (!context.validationError) {
      const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedTime = utcToZonedTime(selectedDate, systemTimezone);
      const timeToUTC = zonedTimeToUtc(zonedTime, storeTimezone);
      handleDateUpdate(timeToUTC, false);
    }
  };

  const handleInputChangeDebounce = debounce(handleInputChange, 2000);

  return (
    <DesktopDatePicker
      label="Date"
      value={value}
      disabled={isLoading}
      timezone={storeTimezone}
      minDate={minDateTime}
      maxDate={maxDateTime}
      open={isPickerOpen}
      onOpen={() => {
        setIsActive(true);
        setIsPickerOpen(true);
      }}
      onClose={() => {
        setIsHovering(false);
        setIsPickerOpen(false);
      }}
      css={{
        width: '160px',
      }}
      slots={{
        openPickerIcon: isLoading ? LoadingIndicator : EventIcon,
      }}
      slotProps={determinePickerSlotProps(setIsActive)}
      onChange={handleInputChangeDebounce}
    />
  );
}

export default JobDatePicker;
