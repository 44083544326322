import HTTP from '../../core/http';
import { objectKeysCamelToSnake } from '../case';
import { DEFAULT_ADVANCED_SEARCH } from '../../state/ducks/suggestAssociate/constants';

export function getMatchedAssociates(matchedAssociatesRequest, signal) {
  const { jobId } = matchedAssociatesRequest;
  return new Promise((resolve, reject) => {
    HTTP.get(`jobs/${jobId}/find-associates`, {
      params: objectKeysCamelToSnake({
        ...DEFAULT_ADVANCED_SEARCH,
        ...matchedAssociatesRequest,
        jobId: undefined,
      }),
      signal,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('There was a problem retrieving the Job record'));
      });
  });
}

export function putAssociate({ associateId, jobId }) {
  return new Promise((resolve, reject) => {
    HTTP.put(`jobs/${jobId}/concierge-claim/`, {
      user_id: associateId,
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeAssociate(jobId) {
  return new Promise((resolve, reject) => {
    HTTP.put(`jobs/${jobId}/concierge-unclaim/`, {})
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateAssociateEventLog(userId, eventLogId, isRead) {
  return new Promise((resolve, reject) => {
    HTTP.put(`availability/user/${userId}/event-log/${eventLogId}/`, {
      is_read: isRead,
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addComment(userId, eventLogId, comment) {
  return new Promise((resolve, reject) => {
    HTTP.post(`availability/user/${userId}/event-log/${eventLogId}/comment`, {
      comment,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
