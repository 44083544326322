/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { getNumberOfUnreadAvailabilityChanges } from '../../../../state/ducks/core/operations';
import auth from '../../../../services/auth';
import AssociateDailyActivityEventDetails from '../AssociateDailyActivityEventDetails';
import AssociateWeeklyActivityEventDetails from '../AssociateWeeklyActivityEventDetails';
import AssociateUnavailableTimeEventDetails from '../AssociateUnavaliableTimeEventDetails';
import { ActivityEvent } from '../style';
import {
  addComment,
  updateAssociateEventLog,
} from '../../../../services/associates';
import { addCommentToEventLogs } from '../../utils';

import * as actions from '../../../../state/ducks/workPreferencesPage/actions';
import ActivityEventCheckbox from '../ActivityEventCheckbox';
import ActivityEventComments from '../ActivityEventComments';
import ActivityEventCommentBox from '../ActivityEventCommentBox';
import ActivityEventCommentButton from '../ActivityEventCommentButton';
import { VARIANTS } from '../../../../constants';

function determineIdPrefix(content_type) {
  switch (content_type) {
    case 'dailyavailability':
      return 'unavailable-time';
    case 'weeklyavailability':
      return 'weekly';
    default:
      return 'daily';
  }
}

const determineId = (eventLog) => {
  const { content_type, id } = eventLog;
  const idPrefix = determineIdPrefix(content_type);
  return `${idPrefix}-activity-event-${id}`;
};

function determineAssociateEventComponent(content_type) {
  switch (content_type) {
    case 'dailyavailability':
      return AssociateDailyActivityEventDetails;
    case 'weeklyavailability':
      return AssociateWeeklyActivityEventDetails;
    default:
      return AssociateUnavailableTimeEventDetails;
  }
}

function AssociateEvent({
  eventLog,
  associateTimezone,
  associateId,
  fetchEventLogs,
}) {
  const eventLogs = useSelector((state) => state.workPreferencesPage.eventLogs);
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);
  const [addedComment, setAddedComment] = useState('');
  const dispatch = useDispatch();
  const selectedManagerId = useSelector((state) => state.manager.id);
  const currentUserId = auth.getCurrentUser().id;
  const AssociateEventDetails = determineAssociateEventComponent(
    eventLog.content_type
  );

  useEffect(() => {
    setAddedComment('');
  }, [commentBoxOpen]);

  const handleSave = () => {
    addComment(associateId, eventLog.id, addedComment)
      .then((comment) => {
        dispatch(
          actions.setEventLogs(
            addCommentToEventLogs(eventLog.id, eventLogs, comment)
          )
        );
        setAddedComment('');
        setCommentBoxOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to add comment to activity: ${error}`, {
          variant: VARIANTS.error,
        });
      });
  };

  const onChange = (checked) => {
    updateAssociateEventLog(associateId, eventLog.id, checked)
      .then(() => {
        fetchEventLogs();
        dispatch(
          getNumberOfUnreadAvailabilityChanges(
            selectedManagerId ?? currentUserId
          )
        );
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to update activity: ${error}`, {
          variant: VARIANTS.error,
        });
      });
  };

  return (
    <ActivityEvent id={determineId(eventLog)} className="associate-event">
      <ActivityEventCheckbox
        isRead={eventLog.is_read}
        id={eventLog.id}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      <Divider orientation="vertical" />
      <Box css={{ width: '100%', padding: '16px 8px 16px 24px' }}>
        <ActivityEventCommentBox
          addedComment={addedComment}
          setAddedComment={setAddedComment}
          commentBoxOpen={commentBoxOpen}
          setCommentBoxOpen={setCommentBoxOpen}
          handleSave={handleSave}
        />
        <ActivityEventComments
          eventLog={eventLog}
          associateTimezone={associateTimezone}
        />
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <AssociateEventDetails
            key={eventLog.id}
            eventLog={eventLog}
            associateTimeZone={associateTimezone}
          />
          <ActivityEventCommentButton setCommentBoxOpen={setCommentBoxOpen} />
        </Box>
      </Box>
    </ActivityEvent>
  );
}

export default AssociateEvent;
