import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { determineBatchEditLabel } from '../utils';

function BatchEditLabel() {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      color={theme.palette.text.secondary}
      css={{ marginBottom: '24px' }}
    >
      {determineBatchEditLabel[currentMode]}
    </Typography>
  );
}

export default BatchEditLabel;
