/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import JobStatusChip from './JobStatusChip';

function JobStatus({ jobId, status, statusAttributes, confirmationStatus }) {
  return (
    <>
      <Box css={{ display: 'flex', gap: '4px' }}>
        <Typography id="job_status" variant="body2" sx={{ paddingBottom: 1 }}>
          {status}
        </Typography>
        {statusAttributes.includes('auto_staffed') && (
          <AutoAwesomeOutlined
            css={{
              width: '20px',
              height: '20px',
            }}
          />
        )}
      </Box>
      {statusAttributes.map((attribute, index) => (
        <JobStatusChip
          jobId={jobId}
          variant="outlined"
          jobDetails={false}
          status={attribute}
          key={`${attribute}-${index}`}
        />
      ))}
      {confirmationStatus && (
        <JobStatusChip
          jobId={jobId}
          variant="outlined"
          status={confirmationStatus}
          jobDetails={false}
        />
      )}
    </>
  );
}

export default JobStatus;
