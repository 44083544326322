import React from 'react';
import { useSelector } from 'react-redux';

function Filter({ filterKey, filterConfiguration }) {
  const { Component, featureFlag, ...rest } = filterConfiguration;
  const featureFlagValue = useSelector(
    (state) => state.featureFlags[featureFlag]
  );

  if (!featureFlag || featureFlagValue) {
    return (
      <Component
        key={`filter-section-${filterKey}`}
        sectionKey={filterKey}
        {...rest}
      />
    );
  }

  return null;
}

export default Filter;
