import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import React from 'react';
import SearchAssociate from '../../JobTable/components/SearchAssociate';
import { NO_ASSOCIATE } from '../constants';
import SuggestAssociateButton from '../../SuggestAssociates/components/SuggestAssociateButton';

function AssociateEdit({
  batchJob,
  selectedAssociate,
  setSelectedAssociate,
  associateEdit,
  setAssociateEdit,
  isLoading,
  isSingleModeOn,
  homeSystem,
}) {
  return associateEdit ? (
    <>
      <SearchAssociate
        id="associate_search"
        label="Associate"
        disabled={isLoading}
        value={selectedAssociate.name}
        onChange={(associate) => {
          setSelectedAssociate({
            name: associate.label,
            ids: [associate.id],
            jobIds: selectedAssociate.jobIds,
          });
        }}
        shouldStopDisable
        onClear={
          homeSystem !== 'SRS'
            ? () => {
                setSelectedAssociate({
                  ...NO_ASSOCIATE,
                  jobIds: selectedAssociate.jobIds,
                });
              }
            : undefined
        }
        onFocus={(event) => {
          setTimeout(() => {
            event.target.select();
          }, 1);
        }}
        autoFocus
        size="medium"
      />
      {isSingleModeOn && (
        <SuggestAssociateButton
          job={batchJob}
          cssOverride={{
            height: '30px',
            width: '190px',
          }}
        />
      )}
    </>
  ) : (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="subtitle3">Associate</Typography>
        <Typography id="current_assigned_associate" variant="body1">
          {selectedAssociate.name}
        </Typography>
      </Box>
      <Button
        id="batch_edit_associate"
        variant="text"
        onClick={() => setAssociateEdit(true)}
        disabled={isLoading}
      >
        Edit
      </Button>
    </Box>
  );
}

export default AssociateEdit;
