import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import { theme } from '../../../styles/theme';

export function UnreadIcon({ cssOverride }) {
  return (
    <CircleIcon
      className="unread-icon"
      css={{
        color: theme.palette.warning.main,
        fontSize: '8px',
        ...cssOverride,
      }}
    />
  );
}
