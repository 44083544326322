import React from 'react';
import { useLocation } from 'react-router-dom';
import GA from '../../services/ga';

const GAWrapper = (props) => {
  const location = useLocation();
  const { children } = props;

  React.useEffect(() => {
    GA.view();
  }, [location]);

  return children;
};

export default GAWrapper;
