import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  Alert,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { updateRowsPerPage } from 'state/ducks/batchView/actions';
import * as operations from 'state/ducks/batchView/operations';
import { getAssociate } from 'services/user';
import Spinner from 'components/Spinner';
import auth from 'services/auth';
import Batch from './components/Batch';
import { ViewBatchPagination } from './style';
import { rowsPerPageOptions } from '../../constants';

function ViewBatchDrawer({ isOpen, onClose }) {
  const theme = useTheme();
  const currentUser = auth.getCurrentUser();
  const timezone = currentUser?.timezone;
  const id = currentUser?.id;
  const [isLoadingBatchView, setIsLoadingBatchView] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isErroredBatchView, setIsErroredBatchView] = useState(false);
  const [isErroredUser, setIsErroredUser] = useState(false);
  const [page, setPage] = useState(0);
  const isErrored = isErroredBatchView || isErroredUser;
  const isLoading = isLoadingBatchView || isLoadingUser;

  const dispatch = useDispatch();
  const batches = useSelector((state) => state.batchView.batches);

  const rowsPerPage = useSelector((state) => state.batchView.cache.rowsPerPage);

  const fetchBatches = () => {
    setIsLoadingBatchView(true);
    setIsErroredBatchView(false);
    dispatch(operations.fetchBatchView(page, rowsPerPage))
      .then(() => {
        setIsLoadingBatchView(false);
      })
      .catch(() => {
        setIsLoadingBatchView(false);
        setIsErroredBatchView(true);
      });
  };

  const fetchUserAndSetTimezone = () => {
    setIsLoadingUser(true);
    setIsErroredUser(false);
    return getAssociate({ id })
      .then((data) => {
        setIsLoadingUser(false);
        auth.setUserTimezone(data[0].profile.timezone);
      })
      .catch(() => {
        setIsLoadingUser(false);
        setIsErroredUser(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (isOpen) {
      if (timezone) {
        fetchBatches();
      } else {
        fetchUserAndSetTimezone().then(() => {
          fetchBatches();
        });
      }
    }
  }, [isOpen, page, rowsPerPage]);

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={isOpen}
      css={{
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Box
        css={{
          width: '400px',
          backgroundColor: theme.palette.backgroundColor.primary,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          css={{
            padding: '16px',
            backgroundColor: theme.palette.backgroundColor.primary,
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            css={{
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
            }}
            spacing={1}
          >
            <Box css={{ display: 'flex' }}>
              <Typography id="batch_drawer_header" variant="h6">
                Batch Process
              </Typography>
              <Button
                id="refresh_batches_btn"
                variant="outlined"
                css={{ marginLeft: '8px', width: '80px', height: '30px' }}
                onClick={fetchBatches}
                disabled={isLoading}
              >
                REFRESH
              </Button>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
        <Divider />

        {isLoading && (
          <Box
            css={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingTop: '50px',
            }}
            id="batches_loading"
          >
            <Spinner height="40" width="40" color="#1584AA" />
          </Box>
        )}

        {isErrored && (
          <Box
            css={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
            id="associate-activity-error-message"
          >
            <Alert
              css={{ marginTop: '48px', width: '320px' }}
              variant="outlined"
              severity="error"
            >
              There was an error loading batches. Refresh or try again later.
            </Alert>
          </Box>
        )}

        {!isLoading &&
          !isErrored &&
          (batches.length === 0 ? (
            <Box
              css={{
                padding: '48px 70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              id="no_batches_view"
            >
              <Typography variant="subtitle1">No Batches</Typography>
              <Typography
                variant="body2"
                css={{
                  textAlign: 'center',
                  color: theme.palette.text.secondary,
                }}
              >
                There are currently no batch processes to display.
              </Typography>
            </Box>
          ) : (
            <Box id="batches" css={{ overflow: 'auto' }}>
              {batches.map((batch) => (
                <Batch batch={batch} key={batch.id} />
              ))}
            </Box>
          ))}

        <ViewBatchPagination
          component="div"
          id="batches_pagination"
          page={page}
          count={1000000000}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setPage(0);
            dispatch(updateRowsPerPage(event.target.value));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to }) => `${from}–${to}`}
          css={{ marginTop: 'auto' }}
        />
      </Box>
    </Drawer>
  );
}

export default ViewBatchDrawer;
