import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as types from './types';
import { DEFAULT_ASSOCIATES_PAGE_SIZE } from './constants';

const pageOfAssociatesReducer = (
  // eslint-disable-next-line default-param-last
  state = [],
  action
) => {
  switch (action.type) {
    case types.SET_ASSOCIATES:
      return action.payload;
    default:
      return state;
  }
};

const cacheReducer = persistReducer(
  {
    key: 'associates',
    storage,
  },
  (
    // eslint-disable-next-line default-param-last
    state = {
      rowsPerPage: DEFAULT_ASSOCIATES_PAGE_SIZE,
    },
    action
  ) => {
    switch (action.type) {
      case types.UPDATE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.payload,
        };
      default:
        return state;
    }
  }
);

export default combineReducers({
  pageOfAssociates: pageOfAssociatesReducer,
  cache: cacheReducer,
});
