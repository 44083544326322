import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { defaultColor, primaryFont } from '../../../styles/theme';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';

function SelectedAssociate() {
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  if (!suggestAssociateJob.user) {
    return null;
  }
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <>
      <Divider />
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: defaultColor,
          padding: '16px',
        }}
      >
        <Box>
          <Typography
            variant="overline"
            component="p"
            css={{
              color: theme.palette.text.secondary,
              lineHeight: '15px',
              letterSpacing: 0,
            }}
          >
            Currently assign to:
          </Typography>
          <Typography
            id="currently_assigned_associate_name"
            variant="subtitle1"
            css={{ fontFamily: primaryFont, lineHeight: '1.25' }}
          >
            {suggestAssociateJob.user.full_name}
          </Typography>
          <Typography
            id="currently_assigned_associate_id"
            variant="body2"
            css={{ color: theme.palette.text.secondary }}
          >
            {suggestAssociateJob.user.external_id}
          </Typography>
        </Box>
        <Box
          css={{
            margin: 'auto 0',
          }}
        >
          <Button
            id="currently_assigned_associate_view_btn"
            variant="outlined"
            onClick={() => {
              dispatch(
                setViewAssociate({
                  user_id: suggestAssociateJob.user.id,
                  hideMatchDetails: true,
                  hideAssignButton: true,
                  ...suggestAssociateJob.user,
                })
              );
            }}
          >
            View
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default SelectedAssociate;
