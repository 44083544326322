import React from 'react';

import { AssociateInfoWrapper } from '../style';
import AssociateActivity from '../AssociateActivity';
import WorkPreferences from './WorkPreferences';

function AssociateWorkPreferences() {
  return (
    <AssociateInfoWrapper className="associate-work-preferences">
      <WorkPreferences />
      <AssociateActivity />
    </AssociateInfoWrapper>
  );
}

export default AssociateWorkPreferences;
