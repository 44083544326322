import * as React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';
import { setSelectedEditField } from '../../../state/ducks/staffingWorkPage/actions';

function SuggestAssociateButton({ job, disabled, cssOverride }) {
  const dispatch = useDispatch();
  return (
    <Button
      variant="text"
      className="associate-suggest-button"
      onClick={() => {
        dispatch(setSelectedEditField(null));
        Promise.resolve(
          dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB))
        ).then(() => {
          dispatch(setSuggestAssociateJob(job));
        });
      }}
      disabled={disabled}
      css={{
        marginTop: '6px',
        width: '100%',
        ...cssOverride,
      }}
    >
      <PeopleIcon
        sx={{
          height: 18,
          width: 18,
          marginRight: '8px',
        }}
      />
      Suggest Associate
    </Button>
  );
}

export default SuggestAssociateButton;
