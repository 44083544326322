import React from 'react';
import { Alert } from '@mui/material';
import { closeSnackbar, SnackbarContent } from 'notistack';
import { VARIANTS } from '../../constants';

const Toast = React.forwardRef(({ id, message, variant }, ref) => {
  const error = variant === VARIANTS.error;
  return (
    <SnackbarContent ref={ref}>
      <Alert
        className="toaster"
        onClose={() => {
          closeSnackbar(id);
        }}
        icon={error ? true : undefined}
        severity={variant}
        variant="filled"
        css={{ width: '400px' }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default Toast;
