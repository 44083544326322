import HTTP from '../../../core/http';
import * as actions from './actions';
import { abort } from '../abort/operations';

export const fetchBatchView =
  (page, rowsPerPage) => async (dispatch, getState) => {
    const sectionKey = 'fetchBatchView';
    await dispatch(abort(sectionKey));
    const {
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const params = {
      page_size: rowsPerPage,
      page: page + 1,
    };

    return HTTP.get(`/bulk/batch/`, {
      signal,
      params,
    })
      .then((response) => {
        dispatch(actions.setBatchView(response.data.results));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching batch view');
        }
      });
  };
