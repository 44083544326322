import axios from 'axios';

import { getApiDomain } from '../config';
import authorizationInterceptor from './interceptors/authorizationInterceptor';
import requestHeadersInterceptor from './interceptors/requestHeadersInterceptor';
import versionUpdateInterceptor from './interceptors/versionUpdateInterceptor';
import csrfTokenInterceptor from './interceptors/csrfTokenInterceptor';
import safariTrailingSlashInterceptorFix from './interceptors/safariTrailingSlashInterceptorFix';
import authorizationRefreshInterceptor from './interceptors/authorizationRefreshInterceptor';
import invalidResponseInterceptor from './interceptors/invalidResponseInterceptor';
import errorDetailInterceptor from './interceptors/errorDetailInterceptor';
import errorLogInterceptor from './interceptors/errorLogInterceptor';

const HTTP = axios.create({
  baseURL: getApiDomain(process.env.REACT_APP_ENV_BUILD),
  timeout: 0,
  withCredentials: true,
});

authorizationInterceptor(HTTP);
authorizationRefreshInterceptor(HTTP);
errorDetailInterceptor(HTTP);
csrfTokenInterceptor(HTTP);
errorLogInterceptor(HTTP);
invalidResponseInterceptor(HTTP);
requestHeadersInterceptor(HTTP);
safariTrailingSlashInterceptorFix(HTTP);
versionUpdateInterceptor(HTTP);

export default HTTP;
