import React from 'react';

import { Button, Typography } from '@mui/material';
import ErrorPage from '../../components/ErrorPage';
import { ReactComponent as AccessDeniedIcon } from '../../assets/empty-states/403-error.svg';

function Unauthorized() {
  return (
    <ErrorPage icon={AccessDeniedIcon}>
      <Typography variant="h6">Access Denied</Typography>
      <Typography variant="body1">
        You do not have access to this page. You can submit a support ticket to
        request access below.
      </Typography>
      <Button
        id="request-access-button"
        variant="outlined"
        sx={{
          margin: '16px 6px',
        }}
        href="https://advantageprod.service-now.com/esc?id=sc_cat_item&sys_id=9e2348ca930c4a50a0a6b80e1dba103d"
      >
        Request Access
      </Button>
    </ErrorPage>
  );
}

export default Unauthorized;
