import React from 'react';
import styled from '@emotion/styled/macro';

import { GeneralTag } from '../../../styles/components';
import { statusesMap } from '../../../styles/theme/colors';
import { capitalizeFirstLetter } from '../../../lib/utils';
import { defaultColor } from '../../../styles/theme';

const JobStatusTagText = styled(GeneralTag)`
  display: inline-block;
  background-color: ${(props) =>
    statusesMap[props.status.replace(' ', '_').toLowerCase()]};

  &:hover {
    color: ${defaultColor};
    text-decoration: none !important;
    opacity: 0.8;
  }
`;

function JobStatusTag({ reviewStatus = null, status }) {
  return (
    <JobStatusTagText
      as="span"
      update={{ sts: status }}
      className={`color-status-bar--text ${status}`}
      status={status}
      reviewstatus={reviewStatus}
    >
      {capitalizeFirstLetter(status)}
    </JobStatusTagText>
  );
}

export default JobStatusTag;
