import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Jyver from './Jyver';
import { determineDisabledText } from '../utils';
import {
  setBatchJobs,
  setIncludesAutoStaffedJob,
  setSelectedEditField,
} from '../../../state/ducks/staffingWorkPage/actions';
import EditPencilOverlay from './EditPencilOverlay';

function AssociateDrawerEditing({ job }) {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleMode = currentMode === 'single';
  const isCanceled = job.status_central === 'Canceled';
  const isCompleted = job.status_central === 'Completed';
  const dispatch = useDispatch();
  const disabledText = determineDisabledText({
    isCanceled,
    isCompletedAssignment: isCompleted,
  });
  const disabled = isCanceled || isCompleted;
  const handleOpenBatchEdit = () => {
    if (isSingleMode) {
      dispatch(setSelectedEditField('associate'));
      dispatch(setBatchJobs([job]));
      dispatch(
        setIncludesAutoStaffedJob(
          job.status_attributes.includes('auto_staffed')
        )
      );
    }
  };

  return (
    <Tooltip title={disabledText} arrow>
      <Box
        onClick={!disabled ? handleOpenBatchEdit : undefined}
        className="job-associate-column"
        css={{
          position: 'relative',
          width: '100%',
          height: '100%',
          ...(isSingleMode && !disabledText
            ? {
                '&:hover > div': {
                  display: 'flex',
                },
              }
            : {}),
        }}
      >
        {job.user && (
          <Jyver
            jobId={job.id}
            avatarUrl={job.user.photo_thumb_url || ''}
            fullName={job.user.full_name}
            phone={job.user.phone_number}
            jobsCompleted={job.user.jobs_completed}
            title={job.user.title}
            divisionName={job.user.division_name}
            employeeId={job.user.external_id}
          />
        )}
        <EditPencilOverlay id="associate-edit" />
      </Box>
    </Tooltip>
  );
}

export default AssociateDrawerEditing;
