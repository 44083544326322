import * as types from './types';

export const setBatchView = (batches) => ({
  type: types.SET_BATCH_VIEW,
  payload: batches,
});

export const updateRowsPerPage = (rowsPerPage) => ({
  type: types.UPDATE_BATCH_VIEW_ROWS_PER_PAGE,
  payload: rowsPerPage,
});
