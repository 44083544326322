/* eslint-disable camelcase */
import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { HeaderWrapper } from '../style';
import { determineAddress, determineHomeStoreAddress } from '../utils';
import AssociateContactActions from './AssociateContactActions';

function Header({ associate, onClose, isLoading }) {
  const { palette } = useTheme();

  const handleCloseDetailsDrawer = () => {
    onClose();
  };

  return (
    <HeaderWrapper>
      {!isLoading && (
        <Stack
          direction="row"
          css={{
            justifyContent: 'space-between',
            alignItems: 'strech',
            margin: '8px',
            height: '100%',
          }}
        >
          <Box>
            <Typography
              variant="h6"
              color={palette.text.primary}
              id="associate_details_full_name"
            >
              {associate.full_name}
            </Typography>
            <Typography
              id="associate_details_employee_id_and_title"
              variant="overline"
              css={{
                color: palette.text.secondary,
                lineHeight: '13.8px',
                letterSpacing: 0,
              }}
            >
              {`${associate.external_id} - ${associate.profile.title}`}
            </Typography>

            <AssociateContactActions
              email={associate.email}
              phoneNumber={associate.phone_number}
              profile={associate.profile}
            />

            <Typography variant="body1" id="associate_details_retailer">
              {associate.division_name}
            </Typography>
            <Typography
              color={palette.text.primary}
              variant="subtitle3"
              lineHeight="24px"
            >
              Home Store:
            </Typography>
            {associate.home_store_location ? (
              <Link
                variant="body1"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  determineAddress(
                    associate.home_store_location.address.city,
                    associate.home_store_location.address.state,
                    associate.home_store_location.address.postal_code
                  )
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box id="associate_details_home_store_location">
                  {determineHomeStoreAddress(
                    associate.home_store_location.chain_name,
                    associate.home_store_location.primary_self_identity,
                    associate.home_store_location.address.city,
                    associate.home_store_location.address.state
                  )}
                </Box>

                <LaunchIcon
                  css={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </Link>
            ) : (
              <Typography
                id="associate_details_home_store_location"
                color={palette.text.secondary}
                variant="body1"
              >
                No home store provided
              </Typography>
            )}
          </Box>
          <Stack
            spacing={10}
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
          >
            <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                id="close_associate_details"
                onClick={handleCloseDetailsDrawer}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      )}
    </HeaderWrapper>
  );
}

export default Header;
