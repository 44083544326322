import React from 'react';
import { Paper, Typography } from '@mui/material';
import { ENV } from '../../core/config';
import { SHOULD_NOT_SHOW_BANNER } from './constants';

export function Banner() {
  if (SHOULD_NOT_SHOW_BANNER) {
    return null;
  }
  return (
    <Paper
      align="center"
      elevation={0}
      sx={{
        backgroundColor: '#d32f2f',
        borderRadius: 0,
        lineHeight: '20px',
      }}
    >
      <Typography
        variant="overline"
        sx={{
          color: 'white',
          lineHeight: 1,
          fontWeight: 600,
        }}
      >
        {ENV}
      </Typography>
    </Paper>
  );
}
