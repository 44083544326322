import * as types from './types';

const jobDetailsReducer = (
  // eslint-disable-next-line default-param-last
  state = {},
  action
) => {
  switch (action.type) {
    case types.SET_JOB_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default jobDetailsReducer;
