import React from 'react';
import { Box, Typography } from '@mui/material';

import LinkCard from './LinkCard';
import { faqDetails, supportTicketDetails } from '../contants';

function Content({ maxWidth }) {
  return (
    <Box
      css={{
        marginTop: '64px',
        maxWidth,
      }}
    >
      <Typography
        css={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
        as="h2"
        variant="h6"
      >
        Categories
      </Typography>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          columnGap: '24px',
          width: '100%',
          '@media (max-width: 1675px)': {
            justifyContent: 'center',
          },
        }}
      >
        <LinkCard id="faq_card" {...faqDetails} />
        <LinkCard id="support_card" {...supportTicketDetails} />
      </Box>
    </Box>
  );
}

export default Content;
