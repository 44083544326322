import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  StyledDialog,
  StyledDialogButton,
  StyledDialogContextText,
  StyledDialogTitle,
} from './style';
import { DEFAULT_ALERT } from '../../state/ducks/dialog/constants';
import { setAlert } from '../../state/ducks/dialog/actions';

function AlertDialog() {
  const alert = useSelector((state) => state.dialog.alert);
  if (isEmpty(alert)) {
    return null;
  }
  const {
    dialog: { title, text: dialogText },
    button: { onClick, text: buttonText },
  } = alert;
  const dispatch = useDispatch();
  return (
    <StyledDialog
      open={!isEmpty(alert)}
      onClose={() => {
        dispatch(setAlert(DEFAULT_ALERT));
      }}
    >
      <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContextText id="alert-dialog-text">
          {dialogText}
        </StyledDialogContextText>
      </DialogContent>
      <DialogActions>
        <StyledDialogButton
          id="alert-dialog-button"
          variant="text"
          onClick={() => {
            onClick();
          }}
        >
          {buttonText}
        </StyledDialogButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default AlertDialog;
