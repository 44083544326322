export const determineAutoStaffingDialog = (autoStaffBeyondHomeStore) => ({
  dialog: {
    title: 'AI Assisted Staffing',
    text: 'For assignments to be eligible for AI assisted staffing, specific requirements must be met. Only assignments that meet these requirements will be shown.',
    listOfText: [
      'Unstaffed or On Hold',
      'Between now and 60 days from now',
      'Work Type: Demonstration, Demo - Adult Beverage',
      ...(autoStaffBeyondHomeStore
        ? []
        : [
            'Location has at least 1 teammate that is within 40 miles of the store',
          ]),
    ],
    revisedDate: autoStaffBeyondHomeStore ? '10/11/2024' : null,
  },
});
