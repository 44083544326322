/* eslint-disable camelcase */
import TextareaFilterSection from '../components/sections/TextareaFilterSection';
import MultiSelectFilterSection from '../components/sections/MultiSelectFilterSection';
import SingleSelectFilterSection from '../components/sections/SingleSelectFilterSection';
import { determineStaffingMethodFilter } from './index';

const parseData = (items, key, value) =>
  new Map(items.map((item) => [item[key], item[value || key]]));

const sectionStructure = {
  statuses: {
    title: 'Status',
    Component: MultiSelectFilterSection,
    endpoint: '/jobs/grouped-by/status',
    parseData: (items) => parseData(items, 'status', 'display_name'),
  },
  staffingMethod: {
    title: 'Staffing Method',
    Component: MultiSelectFilterSection,
    endpoint: '/jobs/grouped-by/staffing-method',
    parseData: (items) =>
      new Map(
        items.map(({ staffing_method }) => [
          staffing_method,
          determineStaffingMethodFilter[staffing_method],
        ])
      ),
    featureFlag: 'showAutoStaff',
  },
  divisions: {
    title: 'Divisions',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'division_name__icontains',
    endpoint: '/jobs/grouped-by/division/',
    parseData: (items) => parseData(items, 'division_id', 'division_name'),
  },
  projectName: {
    title: 'Project Name',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'name__icontains',
    endpoint: '/jobs/grouped-by/name/',
    params: { page_size: 200 },
    parseData: (items) => parseData(items, 'name'),
  },
  projectId: {
    title: 'Project ID',
    Component: TextareaFilterSection,
  },
  assignmentId: {
    title: 'Assignment ID',
    Component: TextareaFilterSection,
  },
  bannerRetailer: {
    title: 'Banner / Retailer',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'store_chain_name__icontains',
    endpoint: '/jobs/grouped-by/store-chain',
    params: { page_size: 200 },
    parseData: (items) => parseData(items, 'store_chain_id', 'store_chain'),
  },
  stores: {
    title: 'Store / Warehouse ID',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'store_primary_self_identity__icontains',
    missingDataSearchKey: 'store_primary_self_identity__in',
    endpoint: '/jobs/grouped-by/store-primary-self-identity',
    params: {
      ordering: 'primary_self_identity',
    },
    parseData: (items) => parseData(items, 'primary_self_identity'),
  },
  tdLinx: {
    title: 'TDLinx',
    Component: TextareaFilterSection,
  },
  brands: {
    title: 'Brand / Client',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'client__icontains',
    endpoint: 'jobs/grouped-by/client',
    params: { is_active: true, page_size: 200 },
    parseData: (items) => parseData(items, 'client_id', 'client'),
  },
  states: {
    title: 'State',
    Component: MultiSelectFilterSection,
    endpoint: 'jobs/grouped-by/geo-store-state',
    parseData: (items) => parseData(items, 'store_state'),
  },
  cityStates: {
    title: 'City',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'geo_store_city_state__icontains',
    endpoint: 'jobs/grouped-by/geo-store-city-state',
    params: { page_size: 200 },
    parseData: (items) => parseData(items, 'store_city_state'),
  },
  zipCodes: {
    title: 'Zip Code',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'geo_store_postal_code__icontains',
    endpoint: 'jobs/grouped-by/geo-store-postal-code',
    params: { page_size: 200 },
    parseData: (items) => parseData(items, 'store_postal_code'),
  },
  jobTypes: {
    title: 'Work Type',
    Component: MultiSelectFilterSection,
    apiSearchKey: 'advantage_type_of_work',
    endpoint: 'jobs/grouped-by/advantage-type-of-work',
    parseData: (items) => parseData(items, 'advantage_type_of_work'),
  },
  homeSystem: {
    title: 'Home System',
    Component: MultiSelectFilterSection,
    endpoint: '/jobs/grouped-by/advantage-source-system',
    displayFormat: (props) => props.name,
    parseData: (items) =>
      parseData(
        items,
        'advantage_source_system',
        'advantage_source_system_name'
      ),
  },
  associateType: {
    title: 'Associate Type',
    Component: MultiSelectFilterSection,
    endpoint: '/jobs/grouped-by/associate-type',
    parseData: (items) =>
      parseData(items, 'associate_type', 'associate_type_name'),
  },
  jyvers: {
    title: 'Associate Name',
    Component: SingleSelectFilterSection,
    apiSearchKey: 'user_full_name__icontains',
    endpoint: '/jobs/grouped-by/user',
    determineSearchParams: (input) =>
      Number.isNaN(parseFloat(input))
        ? { user_full_name__icontains: input }
        : { employee_id__icontains: input },
    parseData: (items) =>
      new Map(
        items.map(({ id, full_name, external_id }) => [
          id,
          `${full_name} - ${external_id}`,
        ])
      ),
  },
};

export default sectionStructure;
