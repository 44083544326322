import styled from '@emotion/styled/macro';
import { Button, Dialog, DialogContentText, DialogTitle } from '@mui/material';
import {
  primaryFont,
  secondaryFont,
  secondaryFontSize,
} from '../../styles/theme/fonts';
import { secondaryColor } from '../../styles/theme/colors';

export const StyledDialog = styled(Dialog)`
  &.MuiPaper-root-MuiDialog-paper {
    width: 900px;
  }

  .MuiModal-root-MuiDialog-root {
    z-index: 10000;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 20px !important;
  font-family: ${primaryFont} !important;
  line-height: 160%;
  color: #263846;
`;

export const StyledDialogContextText = styled(DialogContentText)`
  color: #263846 !important;
  font-family: ${secondaryFont} !important;
  line-height: 150%;
`;

export const StyledDialogButton = styled(Button)`
  font-family: ${secondaryFont} !important;
  font-size: ${secondaryFontSize} !important;
  color: ${secondaryColor} !important;
  line-height: 24px !important;
`;
