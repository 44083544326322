/* eslint-disable camelcase */
import React from 'react';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { formatTimeRange, transformAvailabilityData } from 'lib/utils';
import {
  displayAvailability,
  displayCommuteMiles,
  displayMaxDailyHours,
} from 'containers/Associate/utils';
import { AvailabilityTypography, NoResults } from '../style';
import AssociateAvailabilityInfoRow from '../AssociateAvailabilityInfoRow';

function AssociateFutureAvailability({ future_availability }) {
  const { palette } = useTheme();

  if (!future_availability) {
    return (
      <Box
        css={{
          width: '100%',
          borderBottom: `1px solid ${palette.divider}`,
          paddingBottom: '8px',
        }}
      >
        <AvailabilityTypography variant="subtitle1">
          Future Availability
        </AvailabilityTypography>
        <NoResults id="future-availability-no-result" variant="body1">
          No future availability submitted
        </NoResults>
      </Box>
    );
  }

  const transformedAvailabilityData =
    transformAvailabilityData(future_availability);

  return (
    <Box
      css={{
        width: '100%',
        borderBottom: `1px solid ${palette.divider}`,
        paddingBottom: '8px',
      }}
      id="associate-future-availability"
    >
      <AvailabilityTypography variant="subtitle1">
        Future Availability
      </AvailabilityTypography>

      <AssociateAvailabilityInfoRow label="Effective date">
        <AvailabilityTypography id="associate-effective-date" variant="body1">
          {future_availability.effective_date &&
            format(new Date(future_availability.effective_date), 'MM/dd/yyyy')}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>
      <AssociateAvailabilityInfoRow label="Home store only">
        <AvailabilityTypography id="associate-home-store" variant="body1">
          {displayAvailability(future_availability.my_home_store_only)}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      {!future_availability.my_home_store_only && (
        <AssociateAvailabilityInfoRow label="Willing to drive">
          <AvailabilityTypography
            id="associate-maximum-commute-miles"
            variant="body1"
          >
            {displayCommuteMiles(future_availability.maximum_commute_miles)}
          </AvailabilityTypography>
        </AssociateAvailabilityInfoRow>
      )}

      {!future_availability.my_home_store_only && (
        <AssociateAvailabilityInfoRow label="Travel and stay overnight">
          <AvailabilityTypography
            id="associate-allow-overnight-stay"
            variant="body1"
          >
            {displayAvailability(future_availability.allow_overnight_stay)}
          </AvailabilityTypography>
        </AssociateAvailabilityInfoRow>
      )}

      <AssociateAvailabilityInfoRow label="Preferred hours a week">
        <AvailabilityTypography
          id="associate-preferred-weekly-hours"
          variant="body1"
        >
          {`${future_availability.preferred_weekly_hours} hours`}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      <AssociateAvailabilityInfoRow label="Maximum hours per day">
        <AvailabilityTypography id="associate-max-daily-hours" variant="body1">
          {displayMaxDailyHours(future_availability.preferred_daily_hours)}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      {transformedAvailabilityData.map((availabilityData) => (
        <AssociateAvailabilityInfoRow
          key={`day-${availabilityData.id}`}
          label={
            availabilityData &&
            format(new Date(0, 0, availabilityData.day), 'EEEE')
          }
        >
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
          >
            {availabilityData.time_ranges.length === 0 ? (
              <AvailabilityTypography
                id="time-slot-not-available"
                variant="body1"
              >
                Not Available
              </AvailabilityTypography>
            ) : (
              availabilityData.time_ranges.map((time, index) => (
                <AvailabilityTypography
                  // eslint-disable-next-line react/no-array-index-key
                  key={`time-slot-${index}`}
                  id={`time-slot-${index}`}
                  variant="body1"
                >
                  {formatTimeRange(time)}
                </AvailabilityTypography>
              ))
            )}
          </Box>
        </AssociateAvailabilityInfoRow>
      ))}
    </Box>
  );
}

export default AssociateFutureAvailability;
