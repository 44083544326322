import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import { theme } from '../../../styles/theme';

export function ViewIcon() {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <ChevronRightIcon
        css={{ color: theme.palette.action.active, fontSize: '24px' }}
      />
    </Box>
  );
}
