import React from 'react';
import { useSelector } from 'react-redux';
import AssociateInlineEditing from './AssociateInlineEditing';
import AssociateDrawerEditing from './AssociateDrawerEditing';

function Associate({ job, getUpdatedJob }) {
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const isSingleEditModeEnabled = featureFlags[flagName];

  if (isSingleEditModeEnabled) {
    return <AssociateDrawerEditing job={job} />;
  }

  return <AssociateInlineEditing job={job} getUpdatedJob={getUpdatedJob} />;
}

export default Associate;
