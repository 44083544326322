import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { persistStore } from 'redux-persist';

import reducer from './ducks/rootReducer';

/* eslint-disable no-underscore-dangle */
const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    typeof window === 'object' &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  )
);
/* eslint-enable */

export const persistor = persistStore(store);
export default store;
