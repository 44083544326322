import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from 'containers/Home/constants';
import { enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Jyver from './Jyver';
import { putAssociate, removeAssociate } from '../../../services/associates';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { determineDisabledText, determineShowEditMode } from '../utils';
import { VARIANTS } from '../../../constants';
import LoadingIndicator from './LoadingIndicator';
import SearchAssociate from './SearchAssociate';
import SuggestAssociateButton from '../../SuggestAssociates/components/SuggestAssociateButton';

function AssociateEditing({ job, getUpdatedJob }) {
  const isCanceled = job.status_central === 'Canceled';
  const isCompleted = job.status_central === 'Completed';
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = job;
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const showEditMode = determineShowEditMode(
    isHovering,
    isActive,
    isLoading,
    !isCanceled,
    !isCompleted,
    isSingleMode
  );
  const disabled =
    job.status_central === 'Completed' || job.status_central === 'Canceled';
  const searchInitialValue = user ? user.full_name : null;
  const jobId = job.id;

  const handleAssociateClick = (associateId, callBack) => {
    setIsLoading(true);
    putAssociate({ associateId, jobId })
      .then(() => {
        enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
          variant: VARIANTS.success,
        });
        setIsLoading(false);
        setIsActive(false);
        callBack();
        getUpdatedJob(jobId);
      })
      .catch((error) => {
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Error when assigning or un-assigning',
            error.response.data.detail,
            job.external_identifier,
            job.advantage_source_system_name
          ),
          { variant: VARIANTS.error }
        );
        setIsLoading(false);
        setIsActive(true);
        callBack();
      });
  };

  return (
    <Tooltip
      title={determineDisabledText({
        isCanceled,
        isCompletedAssignment: isCompleted,
      })}
      arrow
    >
      <Box
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        className="job-associate-column"
        css={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100px',
        }}
      >
        <Box
          css={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
          className="search-associates"
        >
          {!showEditMode && job.user && (
            <Jyver
              jobId={job.id}
              avatarUrl={job.user.photo_thumb_url || ''}
              fullName={job.user.full_name}
              phone={job.user.phone_number}
              jobsCompleted={job.user.jobs_completed}
              title={job.user.title}
              divisionName={job.user.division_name}
              employeeId={job.user.external_id}
            />
          )}
          {showEditMode && (
            <>
              <Box
                className="search-associates-wrapper"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <SearchAssociate
                  id="associate_search"
                  sx={{
                    width: '100%',
                  }}
                  value={searchInitialValue}
                  isActive={(newIsActive) => {
                    setIsActive(newIsActive);
                  }}
                  disabled={disabled}
                  onChange={({ id }, errorCallback) =>
                    handleAssociateClick(id, errorCallback)
                  }
                />
                {isLoading && (
                  <Box
                    css={{
                      margin: '7px 9.75px',
                    }}
                  >
                    <LoadingIndicator />
                  </Box>
                )}
                {user && !isLoading && (
                  <IconButton
                    className="associate-unassign-button"
                    onClick={() => {
                      setIsLoading(true);
                      removeAssociate(job.id)
                        .then(() => {
                          enqueueSnackbar(
                            'Associate was removed successfully!',
                            {
                              variant: VARIANTS.success,
                            }
                          );
                          setIsLoading(false);
                          getUpdatedJob(job.id);
                        })
                        .catch((error) => {
                          enqueueSnackbar(
                            formatErrorFromSubsystem(
                              'Error when assigning or un-assigning',
                              error.response.data.detail,
                              job.external_identifier,
                              job.advantage_source_system_name
                            ),
                            { variant: VARIANTS.error }
                          );
                          setIsLoading(false);
                        });
                    }}
                    disabled={disabled}
                    onChange={({ id }, errorCallback) =>
                      handleAssociateClick(id, errorCallback)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
              <Divider />
              <SuggestAssociateButton job={job} disabled={disabled} />
            </>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
}

export default AssociateEditing;
