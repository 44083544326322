export default function safariTrailingSlashInterceptor(axiosInstance) {
  return axiosInstance.interceptors.request.use((config) => {
    const newConfig = Object.assign({}, config);
    if (!newConfig.url) return newConfig;

    const { url } = newConfig;

    if (url.includes('/?')) return newConfig;

    const hasQueryParams = url.includes('?');
    const hasTrailingSlash = url.endsWith('/');

    if (hasQueryParams) {
      newConfig.url = url.replace(/\?/, '/?');
    } else if (!hasTrailingSlash) {
      newConfig.url = `${url}/`;
    }

    return newConfig;
  });
}
