import styled from '@emotion/styled/macro';
import { getTransitionStyles, filterDrawerWidth } from 'styles/theme';
import ZebraStripedDataGrid from '../ZebraStripedDataGrid';

export const HoverableDateTimeDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const StatusChipWrapper = styled.div`
  padding-bottom: ${(props) => (props.jobDetails ? '0' : '8px')};
`;

const marginRight = 0;
export const StyledDataGrid = styled(ZebraStripedDataGrid)(
  ({ isOpen, hasFilters }) => ({
    marginRight: hasFilters ? `-${filterDrawerWidth}px` : 0,
    ...getTransitionStyles(isOpen, marginRight),
    '.MuiDataGrid-cell': {
      display: 'block',
    },
    '.MuiDataGrid-cell:not([data-field="__check__"])': {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '8px',
    },
    '.MuiDataGrid-footerContainer': {
      position: 'fixed',
      top: 'auto',
      height: '54px',
      bottom: 0,
      width: '100%',
      backgroundColor: 'white',
    },
  })
);
