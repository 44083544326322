import store from '../../../state/store';
import { UPDATE_VERSION_ALERT } from '../../../state/ducks/dialog/constants';
import { setAlert } from '../../../state/ducks/dialog/actions';

export default function versionUpdateInterceptor(axiosInstance) {
  return axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 426) {
          store.dispatch(setAlert(UPDATE_VERSION_ALERT));
        }
      }
      return Promise.reject(error);
    }
  );
}
