import React from 'react';
import { Box, Button, Typography } from '@mui/material';

function BatchDetailsErrorView({ doRetry }) {
  return (
    <Box
      css={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        css={{
          width: '400px',
          textAlign: 'center',
        }}
        variant="body1"
      >
        There was an error retrieving batch data. Refresh the screen to try
        again.
      </Typography>
      <Button
        variant="outlined"
        className="button"
        css={{ marginTop: '8px' }}
        onClick={doRetry}
        id="batch-details-refresh-btn"
      >
        Refresh
      </Button>
    </Box>
  );
}

export default BatchDetailsErrorView;
