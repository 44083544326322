import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography } from '@mui/material';
import NumberOfSelectedFiltersTag from './NumberOfSelectedFiltersTag';

function FilterAccordionSummary({ title, sectionKey }) {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      id={`filter-section-${sectionKey}-toggle-btn`}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <NumberOfSelectedFiltersTag sectionKeys={[sectionKey]} />
    </AccordionSummary>
  );
}

export default FilterAccordionSummary;
