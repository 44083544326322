export const sortMapping = {
  asc: '',
  desc: '-',
};

export const rowsPerPageOptions = [10, 20, 30, 50, 100];

export const VARIANTS = {
  success: 'success',
  info: 'info',
  error: 'error',
};
