import { combineReducers } from 'redux';

import abortReducer from './abort/reducers';
import accessReducer from './access/reducers';
import associatesPageReducer from './associatesPage/reducers';
import coreReducer from './core/reducers';
import dialogReducer from './dialog/reducers';
import workPreferencesPageReducer from './workPreferencesPage/reducers';
import featureFlagsReducer from './featureFlags/reducers';
import filtersReducer from './filters/reducers';
import managerReducer from './manager/reducers';
import staffingWorkPageReducer from './staffingWorkPage/reducers';
import suggestAssociateReducer from './suggestAssociate/reducers';
import viewAssociateReducer from './viewAssociate/reducers';
import batchDetailsPageReducer from './batches/reducers';
import batchViewReducer from './batchView/reducers';
import jobDetailsReducer from './jobDetails/reducers';

const rootReducer = combineReducers({
  abort: abortReducer,
  access: accessReducer,
  associatesPage: associatesPageReducer,
  core: coreReducer,
  dialog: dialogReducer,
  workPreferencesPage: workPreferencesPageReducer,
  featureFlags: featureFlagsReducer,
  filters: filtersReducer,
  manager: managerReducer,
  staffingWorkPage: staffingWorkPageReducer,
  suggestAssociate: suggestAssociateReducer,
  viewAssociate: viewAssociateReducer,
  batchView: batchViewReducer,
  jobDetails: jobDetailsReducer,
  batchDetailsPage: batchDetailsPageReducer,
});

export default rootReducer;
