import { Box, Typography } from '@mui/material';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import AssociateDailyAvailability from '../components/AssociateDailyAvailability';
import AssociateFutureAvailability from '../components/AssociateFutureAvailability';
import AssociateUnavailableTimes from '../components/AssociateUnavailableTimes';
import { AssociateInfoWrapper, AssociateLeftColumn } from '../style';
import SkeletonAssociateWorkPreferences from '../components/SkeletonAssociateWorkPreferences';

function WorkPreferences() {
  const { associateWorkPreferencesContext } = useOutletContext();
  const { associateWorkPreferences, isLoading, error, associateTimeZone } =
    associateWorkPreferencesContext;
  if (error) {
    return (
      <AssociateInfoWrapper className="associate-work-preferences">
        <AssociateLeftColumn>
          <Box css={{ margin: 'auto' }}>
            <Typography id="associate-work-preference-error">
              {error}
            </Typography>
          </Box>
        </AssociateLeftColumn>
        <Box css={{ backgroundColor: '#0B3B600A', width: '100%' }} />
      </AssociateInfoWrapper>
    );
  }

  if (isLoading) {
    return <SkeletonAssociateWorkPreferences />;
  }
  return (
    <AssociateLeftColumn>
      {!associateWorkPreferences ? (
        <Typography
          id="work-preferences-no-data"
          variant="body1"
          css={{ margin: 'auto' }}
        >
          No information to display
        </Typography>
      ) : (
        <>
          <AssociateDailyAvailability
            current_availability={associateWorkPreferences.current_availability}
          />
          <AssociateFutureAvailability
            future_availability={associateWorkPreferences.future_availability}
            associateTimeZone={associateTimeZone}
          />
          <AssociateUnavailableTimes
            unavailable_times={associateWorkPreferences.unavailable_times}
            associateTimezone={associateTimeZone}
          />
        </>
      )}
    </AssociateLeftColumn>
  );
}

export default WorkPreferences;
