import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

function EditPencilOverlay({ id }) {
  const { palette } = useTheme();

  return (
    <Box
      id={id}
      css={{
        display: 'none',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: '#F5FBFC',
        border: '1px solid #80C7DD',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      <ModeEditIcon
        css={{
          color: palette.primary.main,
          marginRight: '8px',
          width: '20px',
          height: '20px',
        }}
      />
      <Typography variant="subtitle4" color={palette.primary.main}>
        EDIT
      </Typography>
    </Box>
  );
}

export default EditPencilOverlay;
