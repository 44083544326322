import styled from '@emotion/styled/macro';

import { MenuItem, MenuList, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from 'styles/theme';

export const SecondaryNavigationMenu = styled(MenuList)`
  && {
    display: flex;
    border: 1px solid ${theme.palette.divider};
    padding: 8px 0 0 24px;
  }
`;

export const SecondaryNavigationMenuItem = styled(MenuItem)`
  padding: 8px 16px;
  color: ${theme.palette.text.secondary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;

  &.active {
    color: ${theme.palette.primary.main};
    border-bottom: 2px solid ${theme.palette.primary.main};
  }
  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

export const AssociateContactInfoRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid ${theme.palette.divider};
`;

export const NoResults = styled(Typography)`
  && {
    color: ${theme.palette.text.secondary};
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
  }
`;

export const AssociateWorkPreferencesRowWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
  }
`;

export const AvailabilityTypography = styled(Typography)`
  && {
    color: ${theme.palette.text.primary};
  }
`;

export const ActivityEvent = styled(Box)`
  display: flex;
  background-color: ${theme.palette.backgroundColor.secondary};
  border: 1px solid ${theme.palette.divider};
  border-radius: 16px;
`;

export const ActivityEventInfo = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const ActivityEventStatus = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
