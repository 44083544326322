import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Statuses } from '../style';

export const BATCH_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  UNKNOWN: 'unknown',
  STAFFED: 'staffed',
  NOT_STAFFED: 'not_staffed',
};

export const STATUS_ITEMS = [
  { type: BATCH_STATUS.SUCCESS, label: 'Successful', id: 'success' },
  { type: BATCH_STATUS.STAFFED, label: 'Staffed', id: 'staffed' },
  { type: BATCH_STATUS.NOT_STAFFED, label: 'Not Staffed', id: 'not_staffed' },
  { type: BATCH_STATUS.FAIL, label: 'Failed', id: 'fail' },
  { type: BATCH_STATUS.UNKNOWN, label: 'Unknown', id: 'unknown' },
];

function BatchStatuses({
  successfulRows,
  failedRows,
  unknownRows,
  staffedRows,
  notStaffedRows,
  handleStatusClick,
  statuses,
}) {
  const rows = {
    [BATCH_STATUS.SUCCESS]: successfulRows,
    [BATCH_STATUS.FAIL]: failedRows,
    [BATCH_STATUS.UNKNOWN]: unknownRows,
    [BATCH_STATUS.STAFFED]: staffedRows,
    [BATCH_STATUS.NOT_STAFFED]: notStaffedRows,
  };

  const handleOnCheck = (event, type) => {
    const isChecked = event.target.checked;

    const updatedStatus = isChecked
      ? [...statuses, type]
      : statuses.filter((item) => item !== type);
    handleStatusClick(updatedStatus);
  };

  return (
    <Statuses id="statuses">
      <Typography variant="h6" css={{ fontSize: '14px', lineHeight: '24px' }}>
        FILTER
      </Typography>
      {STATUS_ITEMS.map(({ type, label, id }) => {
        const selected = statuses.includes(type);
        if (rows[type] > 0) {
          return (
            <Box
              id={id}
              css={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    id={`${id}-checkbox`}
                    size="small"
                    checked={selected}
                    onChange={(event) => {
                      handleOnCheck(event, type);
                    }}
                    value={type}
                  />
                }
                label={label}
              />
              <Typography variant="body1">{rows[type]}</Typography>
            </Box>
          );
        }
        return null;
      })}
    </Statuses>
  );
}

export default BatchStatuses;
