import styled from '@emotion/styled/macro';
import { Box, Chip } from '@mui/material';
import ZebraStripedDataGrid from 'components/ZebraStripedDataGrid';

export const AssociateTableFirstItem = styled(Box)`
  margin-left: 40px;
  margin-top: 10px;
`;

export const SearchBoxContainer = styled(Box)`
  padding: 8px 40px 8px 40px;
  display: flex;
  background-color: #f5f6f6;
  flex-direction: row;
  align-items: start;
  gap: 16px;
`;

export const AssociateChip = styled(Chip)`
  color: #0b3b60;
  border-color: #0b3b60;
`;

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const StyledZebraStripedDataGrid = styled(ZebraStripedDataGrid)`
  .MuiDataGrid-footerContainer {
    position: fixed;
    top: auto;
    height: 54px;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
  paddingbottom: 52px;
`;
