/* ACTION TYPE CONTANTS */

export const ACTIVE_HOME_FILTERS_UPDATED =
  'app/filters/ACTIVE_HOME_FILTERS_UPDATED';
export const ACTIVE_QUALITY_FILTERS_UPDATED =
  'app/filters/ACTIVE_QUALITY_FILTERS_UPDATED';
export const SET_SHOW_FILTERS = 'app/filters/SHOW_FILTERS';
export const HIDE_FILTERS = 'app/filters/HIDE_FILTERS';
export const UPDATE_SELECTED_FILTERS = 'app/filters/UPDATE_SELECTED_FILTERS';
export const UPDATE_FILTER_ITEMS = 'app/filters/GET_FILTER_ITEMS';
export const UPDATE_FILTER_QUERIES = 'app/filters/UPDATE_FILTER_QUERIES';
