import { Tooltip } from '@mui/material';
import React from 'react';
import { ContactActionButton } from '../style';

function AssociateContactButton({
  tooltipMessage,
  label,
  disabled,
  Icon,
  href,
  id,
  target,
  rel,
}) {
  return (
    <Tooltip placement="top" arrow title={tooltipMessage}>
      <ContactActionButton
        variant="outlined"
        size="small"
        disabled={disabled}
        href={href}
        id={id}
        target={target}
        rel={rel}
      >
        <Icon css={{ height: '18px', width: '18px', marginRight: '8px' }} />
        {label}
      </ContactActionButton>
    </Tooltip>
  );
}

export default AssociateContactButton;
