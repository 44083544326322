import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import FilterAccordionDetails from './FilterAccordionDetails';
import FilterAccordionSummary from '../FilterAccordionSummary';

function SearchableFiltersList({
  sectionKey,
  parseData,
  endpoint,
  determineSearchParams,
  params,
  apiSearchKey,
  title,
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      id={`filter-section-${sectionKey}`}
      expanded={expanded}
      onChange={(event, _expanded) => {
        setExpanded(_expanded);
      }}
    >
      <FilterAccordionSummary title={title} sectionKey={sectionKey} />
      {expanded && (
        <FilterAccordionDetails
          sectionKey={sectionKey}
          parseData={parseData}
          endpoint={endpoint}
          determineSearchParams={determineSearchParams}
          params={params}
          apiSearchKey={apiSearchKey}
        />
      )}
    </Accordion>
  );
}

export default SearchableFiltersList;
