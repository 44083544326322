import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

function ZebraStripedDataGrid(props) {
  return (
    <DataGridPro
      {...props}
      getRowClassName={({ indexRelativeToCurrentPage }) =>
        indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
    />
  );
}

export default ZebraStripedDataGrid;
