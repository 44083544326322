import HTTP from '../../core/http';
import { objectKeysCamelToSnake } from '../case';

export function getAssociate({ id, fullName, externalId }, cancelToken) {
  return new Promise((resolve, reject) => {
    HTTP.get('users/', {
      cancelToken,
      params: objectKeysCamelToSnake({
        fullName,
        id,
        externalId,
        status: 'active',
        ordering: 'first_name,middle_name,last_name',
      }),
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch(() => {
        reject(
          new Error(
            'There was an error retrieving associate information. Please try again later.'
          )
        );
      });
  });
}

export function getAssociateSchedule(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/jobs/user/${userId}/schedule/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('No jobs associated with associate'));
      });
  });
}

export function getAssociateTimeOff(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/availability/unavailable-time/user/${userId}/schedule/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('No jobs associated with associate'));
      });
  });
}

export function getAssociatePreferences(
  { userId, effectiveDate, storeTimezone },
  cancelToken
) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/availability/scheduling/user/${userId}/day-preferences/`, {
      cancelToken,
      params: {
        effective_date: effectiveDate,
        timezone: storeTimezone,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('Error getting associate day preferences'));
      });
  });
}

export function getAssociateCertifications(userId, cancelToken) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/credentials/user/${userId}/`, {
      cancelToken,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('Error getting associate certifications'));
      });
  });
}

export function getAssociateWorkPreferences(id) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/availability/user/${id}/`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(new Error('No information to display'));
      });
  });
}

export function getUnreadAssociateAvailabilityNumber(managerId) {
  return new Promise((resolve, reject) => {
    HTTP.get('/users/unread_availability_by_manager/', {
      params: {
        status: 'active',
        manager_id: managerId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
