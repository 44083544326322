/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as types from './types';
import { DEFAULT_BATCHES_PAGE_SIZE } from './constants';

const batchReducer = (
  state = { pageOfBatches: [], batchDetails: {} },
  action
) => {
  switch (action.type) {
    case types.SET_BATCH_DETAILS:
      return { ...state, pageOfBatches: action.payload };
    case types.SET_BATCH:
      return { ...state, batchDetails: action.payload };
    default:
      return state;
  }
};

const cacheReducer = persistReducer(
  {
    key: 'batches',
    storage,
  },
  (
    state = {
      rowsPerPage: DEFAULT_BATCHES_PAGE_SIZE,
    },
    action
  ) => {
    switch (action.type) {
      case types.UPDATE_BATCH_DETAILS_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.payload,
        };
      default:
        return state;
    }
  }
);

export default combineReducers({
  batch: batchReducer,
  cache: cacheReducer,
});
